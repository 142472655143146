import React from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { ToastContainer } from 'react-toastify';
// import PaymentForm from './PaymentForm';


// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(`${process.env.REACT_STRIPE_TEST_PUBLISHABLE_KEY}`);

export default function StripeComponent({props}) {

  return (
    <>
      <div>
        {stripePromise && (
          <Elements stripe={stripePromise}>
            {props.children}
          </Elements>
        )}
      </div>
      <ToastContainer />
    </>
  );
};
