import axios from 'axios';
import { baseURL, baseURL1 } from '../../Redux/baseURL';
import { SiteError } from "../../Redux/ActionCreator";
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

export const startTokenRefreshInterval = (retryCount = 0) => {
    // const dispatch = useDispatch();
    const interval = setInterval(async () => {
        try {
            const response = await axios.get(`${baseURL}/api/users/refreshToken`, {
                headers: {
                  "Content-Type": "application/json",
                },
                withCredentials: true
            });
            console.log("response", response);
            if (response.status == 401 || response.status == 403) {
              toast.error("Session expired!", {
                position: "top-center",
              });
              setTimeout(() => {
                window.location.assign(baseURL1);
              }, 50);
            }
        } catch (error) {
            console.error('Token refresh failed:', error);
            console.error('Error refreshing token:', error);

            // Retry logic with exponential backoff
            if (retryCount < 5) { // Retry up to 5 times
              const retryDelay = Math.pow(2, retryCount) * 1000; // Exponential backoff (2, 4 seconds, etc.)
              console.log(`Retrying in ${retryDelay / 1000} seconds...`);
              return new Promise((resolve) => {
                  setTimeout(async () => {
                      resolve(await startTokenRefreshInterval(retryCount + 1));
                  }, retryDelay);
              });
            } else {
              // If retries fail, log out or handle the error appropriately
              console.log('Token refresh failed after multiple attempts, logging out...');
              if (error.response.status == 401 || error.response.status == 403) {
                toast.error("Session expired!", {
                  position: "top-center",
                });
                window.location.assign(baseURL1);
              } else if (error.message === 'Network Error') {
                toast.error("Network Error", {
                  position: "top-center",
                });
                // dispatch(SiteError('Network Error'));
              }
              clearInterval(interval);
            }            
            // Handle refresh token failure (e.g., log out the user)
        }
    }, 360000); // Check every 6 minutes, the token exipires in 20 min. if the first doesn't work, try 6 minutes again
}
