import React from "react";

export default function JobPostSuccessModal(props) {
  return (
    <div onClick={(e) => e.stopPropagation()} className="w-full max-w-[360px] sm:max-w-[500px] md:max-w-[600px] flex  items-center justify-center px-2 relative">

      <div className="flex w-full flex-col">
        <div className="bg-[#5843BD]  py-4 header-border rounded-tl-[24px] px-4 md:px-6 rounded-tr-[24px] flex justify-between items-center">
          <p className="text-white text-[20px] leading-normal font-semibold text-center">Job posted!</p>

          <div onClick={props.closeModal} class=" w-8 h-8 rounded-full bg-[#fff] flex justify-center items-center font-bold text-[#1C1C1C] text-opacity-60 text-[20px] cursor-pointer">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.2103 1.14844L1.14844 11.3612M1.14844 1.14844L11.2103 11.3612" stroke="black" stroke-width="1.02128" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
        </div>
        <div className="w-full flex flex-col rounded-bl-[24px] rounded-br-[24px] items-center gap-3 justify-between bg-white pt-6 pb-8 px-6">
          <div className="py-6 flex justify-center flex-col items-center">
            <svg
              width="43"
              height="43"
              viewBox="0 0 43 43"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="21.5" cy="21.5" r="21" fill="#5843BD" />
              <path
                d="M11.125 21.3372L18.2239 28.0493L32.421 14.625"
                stroke="white"
                stroke-width="5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <p className="text-center text-[#0D0D0D] text-[24px]  font-normal font-['Poppins']">
              Well done! Your job has been<br className="hidden md:block" /> suceessfully published.
            </p>
          </div>
          <div className="flex py-4 justify-center">
            <button
              className="px-6 py-3 bg-[#5843BD] rounded-3xl shadow justify-center items-center gap-2.5 inline-flex"
              onClick={props.closeModal}
            >
              <span className="text-center text-white  text-[12px] sm:text-base font-semibold font-['Poppins']">
                Preview Job Posting
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
