import React, { useState, useEffect } from "react";
import { GamefiedVector } from "../svgs/employersvg";
import { useNavigate } from "react-router-dom";
import { baseURL } from "../../../Redux/baseURL";
import { useDispatch, useSelector } from "react-redux";
import { getEmployerProfile, returnHome } from "../../../Redux/ActionCreator";
import { ToastContainer, toast } from "react-toastify";
import LoaderModal  from "../LoaderModal";

export default function CancelSubscriptionModal({ setCancel, close, operationType, direct, cta, type, flag }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const employer = useSelector(state => state.employer.message);

  const handleCancelPlan = async (type) => {
    // console.log("type", type);
    if (cta == "Upgrade Plan") {
      close("cancel")
    } else {
      if (employer.plan !== undefined) {
        const response = async () => {
          fetch(baseURL + "/api/stripe/cancelPlan", {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include"
          }).then(resp => resp.json())
            .then(resp => {
              if (resp.statusCode == 200 && resp.success === true) {
                toast.success("Plan cancelled successfully.", {
                  position: toast.POSITION.TOP_CENTER,
                });
                setTimeout(() => {
                  dispatch(getEmployerProfile()); // wait for the webhook to update the employer profile
                  close("cancel");
                }, 300);
              } else if (resp.success === false && resp?.message == "Unauthorized") {
                dispatch(returnHome(resp?.status));
              } else if (resp.statusCode == undefined) {
                if (resp?.title == "Error") {
                  setTimeout(() => {
                    toast.error(resp.error?.raw?.message, {
                      position: toast.POSITION.TOP_RIGHT,
                    });
                  }, 50);
                }
              }
              return resp;
            }).catch(err => console.log("my err", err));
        }
        let res = await response();
  
      } else {
        setTimeout(() => {
          toast.warning("You have to subscribe to a plan first. Thanks", {
            position: toast.POSITION.TOP_CENTER,
          })
        }, 50);
      }
    }
  }

  const [ load, setLoad ] = useState(false)
  const handleUpgradePlan = async (type, typeOfAction) => {
    console.log("upgrade type", type);
    if (employer.plan !== undefined) {
      // if (!stripe || !elements) {
      //   // Stripe.js hasn't yet loaded.
      //   // Make sure to disable form submission until Stripe.js has loaded.
      //   return;
      // }
      const response = async () => {
        setLoad(true)
        fetch(baseURL + "/api/stripe/upgradePlan", {
          method: "PATCH",
          body: JSON.stringify({
            subscriptionPlan: type,
            type: typeOfAction
          }),
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include"
        }).then(resp => resp.json())
          .then(resp => {
            setLoad(false)
            if (resp.statusCode == 200 && resp.success === true) {
              toast.success("Plan upgraded successfully", {
                position: toast.POSITION.TOP_CENTER,
              });
              console.log("resp", resp);
              // dispatch(getting_employer_profile_successful(resp.data));
              setTimeout(() => {
                console.log("set time out profile call was called!!")
                dispatch(getEmployerProfile()); // wait for the webhook to update the employer profile
                close("cancel");
              }, 300);
            } else if (resp.success === false && resp?.message == "Unauthorized") {
              dispatch(returnHome(resp?.status));
            } else if (resp.statusCode == undefined) {
              if (resp?.title == "Error") {
                setTimeout(() => {
                  toast.error(resp.error?.raw?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }, 50);
              }
            }
            return resp;
          }).catch(err => console.log("my err", err));
      }
      if (type == employer?.plan) {
        setTimeout(() => {
          toast.warning("You can't upgrade to the same plan. Thanks", {
            position: toast.POSITION.TOP_CENTER,
          })
        }, 50);
      } else {
        let res = await response();
      }

    } else {
      setTimeout(() => {
        toast.warning("You have to subscribe to a plan first. Thanks", {
          position: toast.POSITION.TOP_CENTER,
        })
      }, 50);
    }
  }

  let text = [
    {
      subject: "Downgrade plan instead?",
      header: "Would you like to downgrade your",
      header1: "current plan instead?",
      body: "We recommend that you downgrade your current plan instead of cancelling it. This way you can continue enjoying Moil services and value.",
      button1: cta,
      button2: "No, I will rather cancel",
    },
    {
      subject: "Upgrade plan instead?",
      header: "Would you like to upgrade your current plan instead?",
      body: "We recommend that you downgrade your current plan instead of cancelling it. This way you can continue enjoying Moil services and value.",
      button1: cta,
      button2: "No, I will rather cancel",
    },
    {
      subject: cta,
      header: "You are about to upgrade your current plan",
      body: "Take note that this action is irreversible and you will be debitted the plan charge fee immediately. All your unused credit will be roll over to this new plan.",
      button1: cta,
      button2: "No, I will rather cancel",
    },
  ]

  const [displayText, setDisplayText] = useState(text[0]);
  useEffect(() => {
    if (operationType == "cancel" && direct == false && flag == null) {
      setDisplayText(text[0]);
    } else if (operationType == "upgrade" && direct == false && flag == null) {
      setDisplayText(text[1]);
    } else if (operationType == "upgrade" && direct == true && flag == "text-flag") {
      setDisplayText(text[2]);
    }
  }, [operationType, direct]);

  const handleAction = () => {
    if (direct == false) {
      navigate("/employer/subscribe/upgrade")
    } else {
      if (operationType == "upgrade" && direct == true && (cta == "Upgrade Plan" || cta == "Downgrade Plan")) {
        if (cta == "Upgrade Plan") {
          handleUpgradePlan(type, "upgrade");
        } else {
          handleUpgradePlan(type, "downgrade");
        }
      }
    }
  }

  return (
    <section onClick={(e) => e.stopPropagation()} className="bg-white md:max-w-[550px] sm:max-w-[500px] min-w-[350px] w-[80%] z-100 m-auto rounded-[24px] pb-5 overflow-hidden relative">
      <div className="absolute top-[-2%] -z-1 left-[-20%]">
        <GamefiedVector />
      </div>

      <div className="flex flex-col items-center relative z-[1000] justify-center gap-y-4">

        <div className=" flex  items-center px-4 md:px-6 py-6 justify-between  bg-[#5843BD] w-full border-[#E9F1FF] border-b-[8px] text-[#fff]">
          <div className="text-2xl font-medium leading-none text-center">{displayText?.subject}</div>

          <div onClick={() => setCancel(false)} class=" w-8 h-8 rounded-full bg-[#fff] flex justify-center items-center font-bold text-[#1C1C1C] text-opacity-60 text-[20px] cursor-pointer">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.2103 1.14844L1.14844 11.3612M1.14844 1.14844L11.2103 11.3612" stroke="black" stroke-width="1.02128" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
        </div>

        <div className="w-full ">
          <p className="text-[20px] font-semibold leading-[1.2] text-center text-[#5843BD]">
            {displayText?.header}
            <br className="hidden md:block" />{displayText?.header1}
          </p>

        </div>

        <p className=" text-base font-normal leading-[1.2] text-center max-w-[280px] sm:max-w-[400px]">
          {displayText?.body}
        </p>

        <div className="flex justify-between items-center justify-center flex-col py-4 gap-x-4 w-full px-4 md:px-8 sm:flex-row gap-y-4">
          <button className="w-full md:w-max px-9 py-3 gap-3 rounded-3xl text-base font-semibold leading-normal text-center bg-[#5843BD] text-white" onClick={() => handleAction()}  >
            {/* { operationType == "cancel" ? "Downgrade" : "Upgrade" } plan */}
            {cta}
          </button>

          <button className="w-full md:w-max items-center justify-center flex gap-x-2 px-9 py-3 gap-3 rounded-3xl text-base font-semibold leading-normal text-center text-[#22263A80]" onClick={() => handleCancelPlan()} >
            { cta == "Upgrade Plan" ? "No, I'll try later" : "No, I will rather cancel"}
          </button>
        </div>
      </div>
      { load && <LoaderModal text={cta == "Upgrade Plan" ? "Processing Payment..." : "Processing Request..."} /> }
    </section>
  )
}

