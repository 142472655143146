import React, { useState } from "react";
import timeResolver from "../timeResolver";
import { ToastContainer, toast } from "react-toastify";
import { ShareIcon } from '../../common/svgs/svgs';
import { baseURL1, baseURL } from "../../../Redux/baseURL";
import {
  jobs_posted_success
} from "../../../Redux/ActionCreator";
import { createPortal } from 'react-dom';

export default function JobPostTableItem(props) {

  const [showModal, setShowModal] = useState(false);

  const copyToClipboard = async () => {
    try {
      console.log("analytics", analytics);
      await navigator.clipboard.writeText(`${baseURL1}/jobs/` + props.jobName);
      toast.success('Link copied to clipboard!', { position: toast.POSITION.TOP_CENTER });
      analytics.track("Web job share before login", {
        Type: "Pre-login Job share web",
        time: new Date(),
      });
    } catch (error) {
      console.error('Unable to copy to clipboard:', error);
      toast.error('Failed to copy link to clipboard.', { position: toast.POSITION.TOP_CENTER });
    }
  };

  const closeJob = async () => {
    if (!showModal) {
      setShowModal(true);
    } else {
      try {
        const response = await fetch(`${baseURL}/api/job/close_job/${props.jobName}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        if (data.success == true) {
          toast.success('Job closed successfully', { position: toast.POSITION.TOP_CENTER });
          dispatch(jobs_posted_success(data.data));
          setShowModal(false);
        }
      } catch (error) {
        console.error('Error closing job:', error);
        setShowModal(false);
      }
    }
  };

  const shareMobileLink = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Share Link',
          text: 'Check out this link!',
          url: "https://employee.moilapp.com/jobs/" + props.jobName,
        });
        analytics.track("Mobile job share before login", {
          Type: "Pre-login Job share mobile",
          time: new Date(),
        });
      } catch (error) {
        console.error('Error sharing link:', error);
      }
    } else {
      // Fallback for browsers that don't support navigator.share
      console.warn('navigator.share is not supported.');
      // You can provide a fallback UI or inform the user about alternative ways to share.
    }
  };

  const shareLink = async () => {
    const screenwidth = window.innerWidth;
    console.log(screenwidth);

    screenwidth < 1024 ? await shareMobileLink() : await copyToClipboard();
  }

  return (
    <>
      <div className="w-full py-4 job_shadow rounded-xl bg-white ">
        <div className="w-full flex flex-col sm:flex-row gap-y-4 sm:gap-0 px-4 justify-between items-center">
          <div className="w-full flex flex-col">
            <div className="w-full flex gap-3 items-center">
              <div className="rounded-[4px]">
                <img
                  src={props?.logo}
                  className="min-w-10 rounded-[4px] h-10"
                  alt={props?.jobPosition}
                />
              </div>
              <div className="w-full flex flex-col">
                <p className="w-full text-black text-base font-medium">
                  <span>{props?.jobPosition}</span>
                </p>
                <p className="text-[#000000DE] leading-normal text-xs font-normal">
                  Posted <span>{timeResolver(props?.postAge)}</span>
                </p>
              </div>
            </div>
          </div>
          <div className="w-full flex flex-row items-center md:gap-3 md:justify-end justify-between">
            <span>
              {props.closed && (
                <button
                  className={`w-full py-3 px-5 sm:max-w-[180px] rounded-lg md:whitespace-nowrap shadow border bg-[#5843BD] text-base xsm:text-sm sm:text-base border-neutral-50 border-opacity-20 text-white `}
                  onClick={props.onClick}
                >
                  <span>Review <span className="hidden xl:inline">Details</span></span>
                </button>
              )}
            </span>
            <span>
              {props.closed && (
                <button
                  className="w-full px-5 py-3 rounded-lg sm:max-w-[180px] border border-[#FF663380] justify-center items-center gap-2.5 flex focus:border-2 focus:bg-[#fff] focus:border-[#FF7E1F]"
                  onClick={() => setShowModal(true)}
                >
                  <span className="text-center text-[#FF7E1F] text-base font-semibold">
                    Close <span className="hidden xl:inline">job</span>
                  </span>
                </button>
              )}
            </span>
            <span>
              {props.closed && (
                <button
                  className="w-full px-5 py-3 rounded-lg sm:max-w-[180px] border border-[#FF663380] justify-center items-center gap-2.5 flex focus:border-2 focus:bg-[#fff] focus:border-[#FF7E1F]"
                  onClick={shareLink}
                >
                  <ShareIcon className="fill-[#FF7E1F]" />
                  <span className="text-center md:hidden xl:inline text-[#FF7E1F] text-base font-semibold">
                    Share
                  </span>
                </button>
              )}
            </span>
          </div>
        </div>
      </div>
      {showModal && createPortal(
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="w-full flex px-6 py-4 md:p-0 md:items-center md:justify-center">
            <div className="rounded-[24px] md:self-center bg-white relative flex flex-col gap-y-4 md:gap-y-6 w-full max-w-[550px]">
              <div className="bg-[#5843BD] flex justify-between items-center px-4 sm:px-6 py-3  header-border rounded-tl-[24px] rounded-tr-[24px]">
                <p className="text-white text-base leading-normal font-semibold text-center">Close Job Confirmation</p>

                <div onClick={() => setShowModal(false)} class=" w-8 h-8 rounded-full bg-[#fff] flex justify-center items-center font-bold text-[#1C1C1C] text-opacity-60 text-[20px] cursor-pointer">
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.2103 1.14844L1.14844 11.3612M1.14844 1.14844L11.2103 11.3612" stroke="black" stroke-width="1.02128" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>

              <div className="flex flex-col bg-white rounded-bl-[24px] rounded-br-[24px] items-center pt-6 md:pt-0 pb-6 gap-4 md:gap-6 px-4 sm:px-6 md:px-12">
                <p className="leading-[1.2] rounded-lg font-medium  px-7 py-4 text-center text-black">
                  Are you sure you want to close this job? Closing the job will stop any further applications and it cannot be undone. Please confirm your action.
                </p>

                <div className="flex flex-col w-full gap-y-4 md:flex-row item-center justify-between">
                  <button className="rounded-[24px] py-2 px-6 bg-[#5843BD] text-sm text-center text-[#fff] leading-normal font-semibold"
                    onClick={() =>  closeJob()}
                  >
                    Confirm Close Job
                  </button>
                  <button className="rounded-[24px] py-2 px-6 border border-[#FF6633] text-center justify-center text-[#FF6633] leading-normal flex items-center gap-x-3 font-semibold"
                    onClick={() =>setShowModal(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>,
        document.getElementById('modal')
      )}
      <ToastContainer />
    </>
  );
}
