import React, { useEffect } from 'react'

export default function PreviewPricingItem({ benefits, plan, price, cta, preShow, click, display, operationType, percentOff }) {
  console.log("cta", cta);

  let planType;
  if (plan == "BASIC" && preShow == "monthly") {
    planType = "basic_monthly"
  } else if (plan == "BASIC" && preShow == "annually") {
    planType = "basic_yearly"
  } else if (plan == "STANDARD" && preShow == "monthly") {
    planType = "standard_monthly"
  } else if (plan == "STANDARD" && preShow == "annually") {
    planType = "standard_yearly"
  } else if (plan == "PROFESSIONAL" && preShow == "monthly") {
    planType = "professional_monthly"
  } else if (plan == "PROFESSIONAL" && preShow == "annually") {
    planType = "professional_yearly"
  }

  return (
    <div className={`w-full cursor-pointer min-h-full ${plan === "STANDARD" ? 'bg-[#F9F4FD] rounded-[8px]': 'bg-white'} ${operationType === "upgrade" && "border border-[#E6E9F5] rounded-none"}  hover:mx-4 hover:scale-110 transform transition-transform flex flex-col justify-between gap-y-5 px-4 py-7`}>

      <div className={`${operationType === "subscribe" ? "flex h-full flex-col gap-y-4 justify-between" : "flex flex-col gap-y-4"}`}>
        {operationType !== "subscribe" && <p className="text-[#858BA0] text-center text-sm leading-normal"><span className="text-[#252430] text-[1.5rem] lg:text-[1.75rem] xl:text-[40px] font-[700]">{`$${preShow === "monthly" ? price.monthly : price.annually}`}</span>/{preShow === 'monthly' ? 'Month' : 'Year'}</p>}

        {operationType === "subscribe" && display == true && <div className="flex text-[#000000DE] flex-col gap-y-4">
          <p className="text-[24px] text-center leading-[1.2] font-medium">{plan}</p>
          <ul className="flex flex-col gap-y-1">
            {
              benefits.map((ben, index) => {
                return <li key={index} className="text-sm md:text-xs list-disc leading-[1.5] style-list ml-4 font-medium">{ben}</li>
              })
            }
          </ul>
        </div>}

        {preShow === 'annually' && (
          <p className='text-xs leading-normal text-center text-[#ff6633]'>
            Save {percentOff} with annual billing
          </p>
        )}

        <div>
          {operationType === "subscribe" && <p className="text-[#858BA0] text-center text-sm leading-normal"><span className="text-[#252430] text-[1.5rem] lg:text-[1.75rem] xl:text-[40px] font-[700]">{`$${preShow === "monthly" ? price.monthly : price.annually}`}</span>/{preShow === 'monthly' ? 'Month' : 'Year'}</p>}
          {/* <p className="text-[#858BA0] text-center text-sm leading-normal"><span className="text-[#252430] text-[40px] font-[700]">{`$${preShow === "monthly" ? price.monthly : price.annually}`}</span>{preShow === "monthly" ? "/Month" : "/Year"}</p> */}
          <button className={`py-4 px-6 text-center w-full ${plan === "STANDARD" ? "bg-[#5843BD] text-[#E6E9F5]" : "text-[#5843BD] bg-[#EEECF8]"} rounded-[0.25rem] text-sm leading-normal`} onClick={() => click(planType, cta, operationType)}
          >{cta}</button>
        </div>
      </div>
    </div>
  )
}