import React, { useMemo, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { posting_loading, getEmployerProfile, userName_from_db, getChatInformation } from "../../Redux/ActionCreator";
import { ToastContainer, toast } from "react-toastify";
import HelmetProvide from "../common/helmet";
import RecruiterDashboard from "./moil_recruiter_dashboard";
import { useAnalytics } from "../../context/AnalyticsContext";

export default function EmployerDashboard() {
  const analytics = useAnalytics();
  const employer = useSelector((e) => e.employer);
  let login = useSelector(e => e.login);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const EmployerProfile = useSelector((prev) => prev.employer);
  const userInfo = useSelector(e => e.userInfo.user);
  const body = EmployerProfile.message;
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (login?.profile_status?.profile_status == true || login?.profile_status?.profile_status == "true") {
      navigate("/employer/dashboard");
    }
  }, []);

  const memoization = useMemo(() => {
    const fetchData = async () => {
      if (userInfo === null) {
        dispatch(userName_from_db(token));
      }
      if (userInfo !== null && userInfo !== undefined) {
        dispatch(getChatInformation(userInfo?.profile_id));
      }
    }
    return fetchData;
  }, [dispatch]);

  useEffect(() => {
    dispatch(posting_loading(true));
    memoization();
  }, [memoization]);

  useEffect(() => {
    console.log("Called!!");
    if (body === null) {
      dispatch(userName_from_db(token));
      dispatch(getEmployerProfile());
    }
  }, []);

  const handleJobPost = () => {
    if (body !== null) {
      navigate("/employer/create-job");
    } else {
      setTimeout(() => {
        toast.error(
          "You don't have a profile yet. Kindly create a profile to post a job.",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }, 50);
    }
  };

  console.log(EmployerProfile);

  const handleClick = useCallback(
    () => {
      if (employer.message !== null) {
        return;
      } else {
        analytics.track("Employer Create Profile Btn", {
          Type: "Employer",
          time: new Date(),
          Email: userInfo?.email,
          userId: userInfo?._id,
        });
        navigate("/employer/form");
      }
    }, [employer.message]
  )

  return (
    <>
      <HelmetProvide
        title="Moil | Dashboard"
        description="Explore your employer dashboard on Moil. Manage job listings, connect with talented candidates, and find the best blue-collar talent for your team."
      />
      <main className="md:UserDashboard col-start-1 md:col-start-2 col-end-3 row-start-2 row-end-3 pt-9 pb-6 px-6 flex justify-center lg:flex-row h-calc-150 md:h-calc-70 overflow-x-hidden md:overflow-y-scroll bg-[#FAFAFA] md:mt-12 sm:mt-0">
        <div className="w-full lg:px-0 max-w-[800px] xtraxl:max-w-calculate mx-auto flex flex-col gap-y-6">
          <div className="flex flex-col gap-1">
            <p class="font-sans text-base md:text-2xl lg:text-3xl font-semibold leading-tight text-left flex items-center gap-3">
              Welcome to
              <img
                src="https://res.cloudinary.com/drlcisipo/image/upload/v1705704261/Website%20images/logo_gox0fw.png"
                alt="Moil Logo"
                className="w-[50px] lg:w-[70px] lg:h-[35px]"
              />
            </p>
            <p class="font-sans text-base font-normal leading-6 text-left">
              Your path to efficient hiring starts here.
              <br />
              Ready to post your first job? Get started in minutes.
            </p>
          </div>
          <div className="bg-dashboard-gradient w-full p-8 xl:pr-16  rounded-lg relative">
            <div className="flex flex-col gap-y-4">
              <p class="text-[20px] lg:text-2xl font-semibold leading-relaxed text-white md:max-w-full md:max-w-[220px]">
                Complete your profile in minutes.
              </p>
              <p class="text-base font-normal leading-6 text-white max-w-[450px] lg:max-w-[550px]">
                Our AI technology does the heavy lifting, so you can create and fill roles quickly and easily.

              </p>
              <div className="w-max relative">
                <button id="create-profile" class="w-max px-5 py-3 gap-3 rounded-lg text-white text-base font-medium text-center bg-[#FF6633] animate-none transition-all duration-300 ease-out" onClick={() => navigate("/employer/form")}>
                  Get Started
                </button>
              </div>
            </div>
          </div>

          {/* Website input */}
          {/*           <div className=" p-8 xl:pr-16 flex flex-col gap-y-4 bg-white rounded-[16px] ">
            <p class="text-xl font-semibold leading-normal text-left text-[#5843BD] max-w-[450px] lg:max-w-[650px]">
              Alternatively, you can extract your profile details from your company website.
            </p>

            <p class="text-base font-medium leading-normal text-left text-[#22263A] max-w-[450px] lg:max-w-[650px]">
              Enter your website address in the box below and click on ‘Fetch Details’.
            </p>

            <div className="flex flex-col md:flex-row gap-y-4 items-center gap-x-4">
              <input placeholder="Type website address here" className="p-4 w-full max-w-[300px] rounded-lg border border-indigo-400 border-opacity-30 text-gray-900 focus:ring-indigo-400 focus:border-indigo-400  text-base p-3 w-full rounded-lg border border-indigo-400 border-opacity-30 text-gray-900 focus:ring-indigo-400 focus:border-indigo-400 font-['Poppins'] text-base" type="" />
              <button class="text-base w-full md:w-max font-medium leading-normal py-3 px-5 text-center text-white bg-[#FF6633] rounded-[12px]">
                Fetch Details
              </button>

            </div>

          </div> */}

          {/* posting job */}
          <div className="pb-16">
            <div className="p-8 flex flex-col gap-y-4 bg-white rounded-[16px] ">
              <p class="text-xl font-semibold leading-normal text-left text-[#22263A]">
                Did you know? Posting a job on Moil typically takes just 2 minutes.
              </p>

              <p class="text-base font-medium leading-normal text-left text-[#00000099] max-w-[450px] lg:max-w-[650px]">
                With our streamlined job matching process, you can spend less time on admin and more time focusing on what matters most: building your team.
              </p>

              {/* <button className="px-[17px] py-[10px] flex items-center">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.9331 2.00045L16.1421 30.0757" stroke="#00000099" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M2 15.9336L30.0753 16.1426" stroke="#00000099" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
              </svg>

              <span className="text-base font-medium leading-normal text-left text-[#00000099]"></span>

            </button> */}

            </div>
          </div>

        </div>
      </main >
    </>
  );
}
