import React, { useEffect, useState, useCallback, useMemo, useRef } from "react";
import DashboardOptionLink from "../common/dashboard_options_link";
import DashboardCandidateItem from "../common/dashboard_candidate_item";
import { AddIcon } from "../common/svgs/svgs";
import HelmetProvide from "../common/helmet";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  recommended_candid,
  total_page,
  candidate_category_success,
  candidate_type_success,
  userName_from_db,
  getEmployerAnalytics,
  getEmployerAnalyticsWeekly,
  candidate_skill_match_success,
  candidate_skill_match_loading,
  job_doc_loading,
  getNotification,
  getEmployerProfile,
} from "../../Redux/ActionCreator";
import SearchBarsOnTop from "../common/SearchBars/SearchBarsOnTop";
import { baseURL } from "../../Redux/baseURL";
import PaginationBtn from "../common/paginationBtn";
import { debounce, range } from "lodash";
import { Popover } from "@mui/material";
import LoaderModal from "../common/LoaderModal";
import { getRecommendedCandidateByCategory, getRecommendedCandidate, getRecommendedCandidateBySkills } from "../../utils/recommendationAlgo";
import { ToastContainer, toast } from 'react-toastify';
import { useAnalytics } from "../../context/AnalyticsContext";
import DashboardStatItem from "../common/dashboard_stat_item";
import DashboardLineChart from "../common/line_chart";
import DashboardNotificationItem from "../common/dashboard_notif_item";
import formatDate from "../../utils/formatted";
import { createPortal } from "react-dom";
import { DateRangePicker } from "@matharumanpreet00/react-daterange-picker";


export default function RecruiterDashboard(props) {
  const analytics = useAnalytics();
  const analyticsCus = useSelector(e => e.analytics_weekly.message);
  const recommend = useSelector((e) => e.recommended_candidate.message);
  const recommend_category = useSelector((e) => e.candidate_category.message);
  const recommend_skill = useSelector((e) => e.candidate_skill_match.message);
  let tabSwitch = useSelector((e) => e.candidate_type.message);
  const profile = useSelector(e => e.employer);
  let login = useSelector(e => e.login);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useSelector(e => e.userInfo.user);
  const total_pagee = useSelector((e) => e.total_page);
  const sidebar = useSelector(e => e.sidebarOpen.message);
  const ourtotalpage = total_pagee.message;
  const [currentDashboardPage, setCurrentDashboardPage] = useState(1);
  const [chartToggle, setChartToggle] = useState(false);
  const [chartToggleDuration, setChartToggleDuration] = useState(false);
  const [twoColumn, setChangeColum] = useState(true);
  const [filterJob, setFilterJob] = useState(false);
  const notify = useSelector(e => e.notification);
  let notification_list = notify.message;

  const [matchText, setMatchText] = useState("Industry Matches");
  const [timeFrame, setTimeFrame] = useState("last 7 days");
  const [dateFrame, setDateFrame] = useState(() => {
    const currentDate = new Date();
    const pastDate = new Date();
    pastDate.setDate(currentDate.getDate() - 7); // Set the date 30 days before the current date
    return [pastDate, currentDate];
  });
  const [anchorEL, setAnchorEL] = useState(null);
  const open = Boolean(anchorEL);
  const idDuration = open ? 'duration-popover' : undefined;
  const upload_status = useSelector((state) => state.jobs_doc);
  const [modal, setModal] = useState(false);
  const startDateRef = useRef();
  const endDateRef = useRef();

  const [type, setType] = useState("jobs");

  let [tabColor, setTabColor] = useState(null);

  let [load, setLoad] = useState(false);



  const filterJobFunc = () => {
    setFilterJob(!filterJob)
  }

  const handleClick = (event) => {
    if (anchorEL) {
      setAnchorEL(null); // close popover if open
    } else {
      setAnchorEL(event.currentTarget); // open popover if closed
    }
  };

  const handleClose = () => {
    setAnchorEL(null);
  };


  const chartToggleFunc = () => {
    setChartToggle(!chartToggle)
  }

  const chartToggleFuncDuration = () => {
    setChartToggleDuration(!chartToggleDuration)
  }

  // get notification
  useEffect(() => {
    if (login?.profile_status?.AcctType === "Official") {
      dispatch(getNotification());
    }
  }, [])

  useEffect(() => {
    if (login?.profile_status?.profile_status == false || login?.profile_status?.profile_status == "false") {
      navigate("/employer/welcome");
    }
    if (upload_status.message !== null) {
      dispatch(job_doc_loading(true));
    } // empty uploaded job doc
    dispatch(userName_from_db());
    dispatch(getEmployerAnalyticsWeekly(dateFrame[0], dateFrame[1], 'last 7 days'));
    dispatch(getEmployerProfile());
  }, []);

  // useEffect(() => {
  //   if (profile.message == null) {
  //     navigate("/employer/welcome");
  //   }
  // }, []);


  const memoizeData = useMemo(() => {
    const fetchData = () => {
      if (tabSwitch === "title" || tabSwitch == "titlet") {
        // setLoad(true)
        let recommendation = getRecommendedCandidate(currentDashboardPage); // we will need to modify this while working with more datas (pagination)
        recommendation.then(resp => resp.json())
          .then(resp => {
            console.log("resp?.data", resp)
            setLoad(false);
            setMatchText("Job Matches");
            if (resp.success == true) {
              if (resp?.data !== null && resp?.data?.result?.length !== 0) {
                dispatch(recommended_candid(resp.data?.result));
                dispatch(total_page(resp.data?.page_num));
              } else {
                setLoad(false);
                if (tabSwitch !== "titlet") {
                  dispatch(candidate_type_success("category"));
                }
              }
            } else {
              // do sth here;
              if (tabSwitch !== "titlet") {
                dispatch(candidate_type_success("category"));
              }
            }
          }).catch(err => {
            console.log("An error occured", err);
            setLoad(false);
            setTimeout(() => {
              toast.error("An Error occured. Please check your network and try again.", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }, 50);
          })
      }

      if (tabSwitch === "category" || tabSwitch == "categoryc") {
        // setLoad(true)
        let recommendation = getRecommendedCandidateByCategory(currentDashboardPage); // we will need to modify this while working with more datas (pagination)
        recommendation.then(resp => resp.json())
          .then(resp => {
            console.log("resp?.data", resp)
            setLoad(false);
            setMatchText("Job Matches");
            if (resp.success == true) {
              if (resp?.data !== null && resp?.data?.result?.length !== 0) {
                dispatch(candidate_category_success(resp.data?.result));
                dispatch(total_page(resp.data?.page_num));
              } else {
                // do sth here;
                dispatch(candidate_category_success([]));
                dispatch(total_page(1));
              }
            } else {
              // do sth here;
              if (resp.success == false) {
                navigate("/");
              }
              // dispatch(candidate_category_success([]));
              // dispatch(total_page(1));
            }
          }).catch(err => {
            console.log("An error occured", err);
            setLoad(false);
            setTimeout(() => {
              toast.error("An Error occured. Please check your network and try again.", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }, 50);
          })
      }

      if (tabSwitch === "skills" || tabSwitch == "skills") {
        // setLoad(true)
        let recommendation = getRecommendedCandidateBySkills(currentDashboardPage); // we will need to modify this while working with more datas (pagination)
        recommendation.then(resp => resp.json())
          .then(resp => {
            console.log("resp?.data", resp)
            setLoad(false);
            setMatchText("SKills Matches");
            if (resp.success == true) {
              if (resp?.data !== null && resp?.data?.result?.length !== 0) {
                dispatch(candidate_skill_match_success(resp.data?.result));
                dispatch(total_page(resp.data?.page_num));
              } else {
                // do sth here;
                dispatch(candidate_skill_match_success([]));
                dispatch(total_page(1));
              }
            } else {
              // do sth here;
              if (resp.success == false) {
                navigate("/");
              }
              // dispatch(candidate_category_success([]));
              // dispatch(total_page(1));
            }
          }).catch(err => {
            console.log("An error occured", err);
            setLoad(false);
            setTimeout(() => {
              toast.error("An Error occured. Please check your network and try again.", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }, 50);
          })
      }

      console.log(
        "currentPage, totalPages 🚀🚀🚀🚀",
        currentDashboardPage,
        ourtotalpage
      );
    }
    return fetchData;
  }, [tabSwitch, currentDashboardPage]);

  useEffect(() => {
    memoizeData();
  }, [memoizeData, tabSwitch, currentDashboardPage]);

  const getRecommendedEmployeeByLastJobTitle = () => {
    // setLoad(true);
    setTabColor("title");
    setMatchText("Job Matches");
    dispatch(candidate_type_success("titlet"));
    let recommendation = getRecommendedCandidate(currentDashboardPage); // we will need to modify this while working with more datas (pagination)
    recommendation.then(resp => resp.json())
      .then(resp => {
        setLoad(false);
        if (resp.success == true) {
          if (resp?.data !== null && resp?.data?.result?.length !== 0) {
            dispatch(recommended_candid(resp.data?.result));
            dispatch(total_page(resp.data?.page_num));
          } else {
            // do sth here;
            dispatch(recommended_candid([]));
            dispatch(total_page(1));
          }
        } else {
          // do sth here;
          // if (resp?.title === "Not Found") {
          //   dispatch(recommended_candid([]));
          //   dispatch(total_page(1));
          // }
          if (resp.success == false) {
            navigate("/");
          }
        }
      }).catch(err => {
        console.log("An error occured", err);
        setLoad(false);
        setTimeout(() => {
          toast.error("An Error occured. Please check your network and try again.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }, 50);
      })
  }

  const getRecommendedEmployeeByCategory = useCallback(
    () => {
      // setLoad(true);
      setTabColor("category");
      setMatchText("Industry Matches");
      dispatch(candidate_type_success("categoryc"));
      let recommendation = getRecommendedCandidateByCategory(currentDashboardPage); // we will need to modify this while working with more datas (pagination)
      recommendation.then(resp => resp.json())
        .then(resp => {
          setLoad(false);
          if (resp.success == true) {
            if (resp?.data !== null && resp?.data?.result?.length !== 0) {
              dispatch(candidate_category_success(resp.data?.result));
              dispatch(total_page(resp.data?.page_num));
            } else {
              // do sth here;
              dispatch(candidate_category_success([]));
              dispatch(total_page(1));
            }
          } else {
            // do sth here;
            // dispatch(candidate_category_success([]));
            // dispatch(total_page(1));
            if (resp.success == false) {
              navigate("/");
            }
          }
        }).catch(err => {
          console.log("An error occured", err);
          setLoad(false);
          setTimeout(() => {
            toast.error("An Error occured. Please check your network and try again.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }, 50);
        })
    }, []
  )

  const getRecommendedEmployeeBySkills = useCallback(
    () => {
      // setLoad(true);
      setTabColor("skills");
      dispatch(candidate_type_success("skills"));
      setMatchText("Skills Matches");
      let recommendation = getRecommendedCandidateBySkills(currentDashboardPage); // we will need to modify this while working with more datas (pagination)
      recommendation.then(resp => resp.json())
        .then(resp => {
          setLoad(false);
          if (resp.success == true) {
            if (resp?.data !== null && resp?.data?.result?.length !== 0) {
              dispatch(candidate_skill_match_success(resp.data?.result));
              dispatch(total_page(resp.data?.page_num));
            } else {
              // do sth here;
              dispatch(candidate_skill_match_success([]));
              dispatch(total_page(1));
            }
          } else {
            // do sth here;
            // dispatch(candidate_category_success([]));
            // dispatch(total_page(1));
            if (resp.success == false) {
              navigate("/");
            }
          }
        }).catch(err => {
          console.log("An error occured", err);
          setLoad(false);
          setTimeout(() => {
            toast.error("An Error occured. Please check your network and try again.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }, 50);
        })
    }, []
  )

  console.log(
    "currentPage, totalPages 🚀🚀🚀🚀",
    currentDashboardPage,
    ourtotalpage,
    "tabSwitch", tabSwitch
  );

  // BigSam
  const [hideSearchResults, setHideSearchResults] = useState(false);
  const [OurSearchLoading, setOurSearchLoading] = useState(false);

  const [JobListData, setJobListData] = useState([]);
  const [totalPages, setTotalPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [initialSearchDone, setInitialSearchDone] = useState(false);
  const [ourError, setOurError] = useState("");

  // Job Search
  const [searchJob, setSearchJob] = useState("");

  function HandleCancelJobSearch() {
    setSearchJob("");
    HandleOverAllSearch();
    setHideSearchResults(false);
  }

  //    Location Search
  const [searchLocation, setSearchLocation] = useState("");

  function HandleCancelLocationSearch() {
    setSearchLocation("");
    HandleOverAllSearch();
    setHideSearchResults(false);
  }

  const HandleOverAllSearch = async () => {
    if (searchJob || searchLocation !== "") {
      setHideSearchResults(true);
      setOurSearchLoading(true);
      setInitialSearchDone(true);
      setOurError("");

      const queryParams = [];
      if (searchJob) {
        queryParams.push(
          `title=${encodeURIComponent(searchJob.toLowerCase())}`
        );
      }
      if (searchLocation) {
        queryParams.push(
          `location=${encodeURIComponent(searchLocation.toLowerCase())}`
        );
      }
      if (currentPage) {
        queryParams.push(`limit=10&page=${currentPage}`);
      }
      const queryString = queryParams.join("&");

      try {
        const response = await fetch(
          baseURL + `/api/search/employee?${queryString}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              // Authorization: "Bearer " + localStorage.getItem("token"),
            },
            credentials: "include"
          }
        )
          .then((res) => res.json())
          .then((data) => {
            if (data.success) {
              const respJson = data;
              console.log("%Results%======>", respJson);
              let ourGuy = respJson;
              console.log(ourGuy);
              setOurSearchLoading(false);
              setTotalPages(respJson?.data.number_of_pages);
              setJobListData(respJson?.data["profile_matches"]);
              console.log("OurJobListData", JobListData);
              console.log("RespJsonEmployerSearch 🚀🚀🚀🚀", respJson);
            } else {
              console.log("why>>??", data);
              console.log("No records match this search criteria");
              setOurError("No records match this search criteria");
              setOurSearchLoading(false);
            }
          });
      } catch (error) {
        console.error("Error fetching data:", error);
        setOurSearchLoading(false);
      }
    }
  };

  // const debouncedSearch = debounce(HandleOverAllSearch, 150);

  useEffect(() => {
    if (initialSearchDone) {
      HandleOverAllSearch();
      // debouncedSearch();
    }
  }, [currentPage, initialSearchDone]);

  const handleJobPost = () => {
    if (profile.message !== null) {
      analytics.track("Post job btn click", {
        Type: "Employer",
        time: new Date(),
        Email: profile?.message?.email,
        Company_name: profile.message?.name,
        profileId: profile.message?._id,
        userId: profile.message?.userId
      });
    }
    if (profile.message?.plan == undefined) {
      toast.error("Please subscribe to a plan to post a job", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setTimeout(() => {
        navigate("/employer/subscribe");
      }, 50);
    } else if (profile.message?.plan_status == "Inactive") {
      toast.error("You have already exhausted your plan credits. Kindly renew or upgrade your plan.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setTimeout(() => {
        navigate("/employer/subscribe");
      }, 50);
    } else {
      navigate("/employer/upload");
    }
  }

  let [topMonth, setTopMonth] = useState("");
  let [lowestMonth, setLowestMonth] = useState("");
  let employerAnalytics = useSelector(e => e.analytics.message);
  useEffect(() => {
    // let get the higherst month there and lowest month
    let stat = employerAnalytics?.stat;
    if (stat !== undefined && stat !== null) {
      if (stat?.length > 0) {
        let lowest = stat[0]?.jobs;
        let lowestIndex = 0;
        stat?.forEach(e => {
          if (lowest > e.jobs) {
            lowest = e.jobs;
            lowestIndex = stat.indexOf(e);
          }
        });
        setLowestMonth(`${stat[lowestIndex]?.month}, ${stat[lowestIndex]?.Year}`);

        let highest = stat[0]?.jobs;
        let highestIndex = 0;
        stat?.forEach(e => {
          if (highest < e.jobs) {
            highest = e.jobs;
            highestIndex = stat.indexOf(e);
          }
        });
        setTopMonth(`${stat[highestIndex]?.month}, ${stat[highestIndex]?.Year}`);
      }
    }
  }, []);

  const handleSetClick = () => {
    // Retrieve the values from the input fields
    const startDateValue = startDateRef.current.value;
    const endDateValue = endDateRef.current.value;

    // Validate the input dates
    if (!startDateValue || !endDateValue) {
      toast.error('Please select both start and end dates.');
      return;
    }
    const ranges = {
      startDate: startDateValue,
      endDate: endDateValue,
    };

    // Parse the dates
    const startDate = new Date(ranges.startDate);
    const endDate = new Date(ranges.endDate);
    setDateFrame([startDate, endDate]);
    dispatch(getEmployerAnalyticsWeekly(startDate, endDate, 'custom'));

    // Close the modal
    setModal(false);
  };

  const getTotalViews = (jobsArray) => {
    let views = jobsArray
      ?.map(job => job.impressions.length)  // Get the number of impressions for each job
      ?.reduce((total, impressions) => total + impressions, 0);  // Sum them up
    console.log(views);
    return views;
  };

  let showModal = modal ? createPortal(
    <div onClick={() => setModal(false)} className="top-0 left-0 fixed bottom-0 right-0 z-10 h-screen flex justify-center items-center md:bg-black md:bg-opacity-70 backdrop-blur-[2px] sm:bg-white xsm:bg-white">
      <div className="hidden md:block md:w-max" onClick={(e) => e.stopPropagation()} >
        <DateRangePicker
          open={true}
          onChange={(ranges) => {
            console.log(ranges);
            const startDate = new Date(ranges.startDate);
            const endDate = new Date(ranges.endDate);
            setDateFrame([startDate, endDate]);
            dispatch(getEmployerAnalyticsWeekly(startDate, endDate, 'custom'));
            setModal(false);
          }}
        />
      </div>

      <div
        onClick={(e) => e.stopPropagation()}
        className="flex flex-col gap-y-4 rounded-md  xsm:max-w-[320px] w-full bg-white p-6 md:hidden shadow-lg"
      >
        <div className="flex flex-col gap-y-4">
          <div className="flex flex-col gap-y-1">
            <p>Start Date</p>
            <input
              ref={startDateRef}
              type="date"
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors"
              aria-label="Start Date"
            />
          </div>
          <div className="flex flex-col gap-y-1">
            <p>End Date</p>
            <input
              ref={endDateRef}
              type="date"
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors"
              aria-label="End Date"
            />
          </div>
        </div>

        <button
          className="rounded-[3px] bg-[#5843BD] text-white px-4 py-2 hover:bg-[#4831A3] focus:outline-none focus:ring-2 focus:ring-[#5843BD] transition-colors cursor-pointer"
          onClick={handleSetClick}
        >
          Set
        </button>
      </div>
    </div>, document.getElementById("modal")
  ) : null;


  return (
    <>
      <HelmetProvide
        title="Moil | Dashboard"
        description="Explore your employer dashboard on Moil. Manage job listings, connect with talented candidates, and find the best blue-collar talent for your team."
      />
      <div className="flex justify-center bg-[#FAFAFA] overflow-y-scroll h-calc-150 md:h-calc-70 pt- pt-12 md:px-6 sm:mt-0">
        <div className="px-6 lg:px-0 relative w-full flex flex-col gap-y-6 lg:max-w-[1000px] xl:max-w-[1100px] xllarge:max-w-[1200px]">

          <div className="flex lg:items-center gap-y-4 flex-col lg:flex-row lg:justify-between w-full">
            <div className="">
              <p className="text-[20px] md:text-[24px] leading-[1.5] font-semibold capitalize text-black">Hello {profile?.message?.name?.split(" ")[0]?.toLowerCase()}, welcome to your dashboard</p>
              <p className="text-base leading-[1.5] font-semibold text-black">Take a look at your performance summary and analytics.</p>
            </div>

            <DashboardOptionLink
              header="Post a Job"
              description={`Use our AI technology to create a job post in quick, easy steps.`}
              ellipseStyle="bg-white left-[60%] top-[10%]  bg-opacity-80 rounded-full blur-[77.64px] ipad:left-[181px] top-[-100px]"
              bgBack="dash-link-grad"
              svgType="postJob"
              onClick={handleJobPost}
              leftBordStyle="bg-[#FF6633]"
            />
          </div>

          {/* do not delete this commented part. it is commented out for future use. */}
          {/* <div
            className={` my-10 ${
              hideSearchResults ? "" : "mx-4 px-4 desktop:px-8 shadow-md"
            } py-[45px] rounded-2xl flex flex-col items-center gap-4`}
          >
            {hideSearchResults ? (
              <h1 className=" tablet:font-medium text-xl font-normal tablet:text-[28px] text-black text-opacity-60 w-full">
                Filter your preferences
              </h1>
            ) : (
              <p className=" font-semibold text-base text-black">
                Search for workers around you
              </p>
            )}

            <div className="w-full">
              <SearchBarsOnTop
                JobListData={JobListData}
                searchJob={searchJob}
                setSearchJob={setSearchJob}
                searchLocation={searchLocation}
                setSearchLocation={setSearchLocation}
                HandleCancelJobSearch={HandleCancelJobSearch}
                HandleCancelLocationSearch={HandleCancelLocationSearch}
                HandleOverAllSearch={HandleOverAllSearch}
                OurSearchLoading={OurSearchLoading}
              />

              {ourError ? (
                <h1 className=" mt-4 tablet:font-medium text-xl font-normal tablet:text-[28px] text-black text-opacity-60 w-full">
                  {ourError}
                </h1>
              ) : (
                <div
                  className={`${hideSearchResults ? "block" : "hidden"} mt-8`}
                >
                  {OurSearchLoading ? (
                    // "Loading..."
                    <LoaderModal text="Loading..." />
                  ) : (
                    <>
                      <div>
                        <HorizontalNFilterIcon />
                        <p className=" mt-[8px] pl-[26px] text-base font-normal text-black text-opacity-60">
                          Filter result
                        </p>
                      </div>

                      <p className=" mt-4 text-xl font-normal tablet:text-[28px] tablet:font-medium text-black text-opacity-60">
                        <span>{JobListData?.length}</span> results found
                      </p>

                      <div className="flex justify-center">
                        <div className=" mt-4 grid grid-cols-1 ipad:grid-cols-2 tablet:gap-x-4 xtraxl:grid-cols-3 place-items-start gap-y-6">
                          {JobListData?.map((items, index) => (
                            <DashboardCandidateItem
                              key={items?.userId?.profile_id}
                              candidate={items?.Fullname}
                              candidateJob={items?.Job_type}
                              candidateLocation={items?.Address}
                              candidateExperienceLevel={
                                items?.Years_of_experience
                              }
                              candidateBio={items?.About}
                              candidateRating={items?.Rating}
                              candidateSalary="35"
                              profileId={items?.userId[0]?.profile_id}
                              candidateImage={items?.photo}
                            />
                          ))}
                        </div>
                      </div>

                      <PaginationBtn
                        totalPages={totalPages}
                        currentPage={currentPage}
                        onNextPage={() => {
                          if (currentPage < totalPages) {
                            setCurrentPage((prev) => prev + 1);
                          }
                        }}
                        onPrevPage={() => {
                          if (currentPage > 1) {
                            setCurrentPage((prev) => prev - 1);
                          }
                        }}
                        onSelectPage={(selectedPage) => {
                          setCurrentPage(selectedPage);
                        }}
                      />
                    </>
                  )}
                </div>
              )}
            </div>
          </div> */}

          <div className="flex gap-y-2 flex-col w-full justify-start">
            <div className="flex items-center gap-x-2">
              <p className="text-base leading-normal font-medium text-[#131313]">Data Range</p>
              <div>
                <div aria-describedby={idDuration} onClick={handleClick} className="bg-white rounded-lg text-base p-2 cursor-pointer flex items-center gap-x-1 leading-normal text-[#5843BD]">
                  <p className="capitalize">{timeFrame}</p>
                  <svg className={open && 'rotate-180'} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.5 5.25L7 8.75L10.5 5.25" stroke="#7D7D7D" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
                <Popover
                  id={idDuration}
                  open={open}
                  anchorEl={anchorEL}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                >
                  <div className="p-4 leading-normal text-[#000000CC] flex justify-start min-w-[150px] md:min-w-[200px] flex-col gap-y-4 bg-white rounded-[4px] ">
                    <div onClick={() => {
                      const currentDate = new Date();
                      const sevenDaysAgo = new Date(currentDate);
                      sevenDaysAgo.setDate(currentDate.getDate() - 7);
                      setDateFrame([sevenDaysAgo, currentDate]);
                      setTimeFrame('last 7 days'.toLowerCase());
                      dispatch(getEmployerAnalyticsWeekly(sevenDaysAgo, currentDate, 'last 7 days'));
                      handleClose();
                    }}
                      className="cursor-pointer">
                      Last 7 days
                    </div>
                    <div className="cursor-pointer" onClick={() => {
                      const currentDate = new Date();
                      const thirtDaysAgo = new Date(currentDate);
                      thirtDaysAgo.setDate(currentDate.getDate() - 30);
                      setDateFrame([thirtDaysAgo, currentDate]);
                      setTimeFrame('last 30 days'.toLowerCase());
                      dispatch(getEmployerAnalyticsWeekly(thirtDaysAgo, currentDate, 'last 30 days'));
                      handleClose();
                    }}>Last 30 days</div>

                    <div className="cursor-pointer" onClick={() => {
                      setModal(true);
                      setTimeFrame('custom'.toLowerCase());
                      handleClose();
                    }}>Custom</div>
                  </div>
                </Popover>
              </div>
            </div>
            <p className="text-[#131313] text-sm leading-[1.5] font-[300]"><em>Note: Date start from {formatDate(dateFrame[0])} to {formatDate(dateFrame[1])}</em></p>
          </div>

          <div className="grid gap-4
            grid-cols-2 md:grid-cols-3 xl:grid-cols-5">
            <DashboardStatItem
              percentageDifference={
                parseInt(analyticsCus?.percentageDifference?.viewPercentage) || 0
              }
              header="Jobs Views"
              btn_text="view All jobs"
              bgColor="bg-white"
              value={analyticsCus?.totalView || 0}
              link="/employer/jobs"
              className="col-span-2 xl:col-span-1"
            />
            <DashboardStatItem
              percentageDifference={
                analyticsCus?.percentageDifference?.jobPercentage || 0
              }
              header="Jobs posted"
              btn_text="View all jobs"
              bgColor="bg-white"
              value={analyticsCus?.totalJobs || 0}
              link="/employer/jobs"
            />
            <DashboardStatItem
              percentageDifference={
                analyticsCus?.percentageDifference?.applicationPercentage || 0
              }
              header="Applications"
              btn_text="View applications"
              bgColor="bg-[#F1FBFF]"
              value={analyticsCus?.totalApplications || 0}
              link="/employer/jobs"
            />
            <DashboardStatItem
              percentageDifference={0}
              header="Active jobs"
              btn_text="View active jobs"
              bgColor="bg-white"
              value={analyticsCus?.totalActive || 0}
              link="/employer/jobs?status=active"
            />
            <DashboardStatItem
              percentageDifference={0}
              header="Pending jobs"
              btn_text="Pending jobs"
              bgColor="bg-[#FBF5F6]"
              value={analyticsCus?.pendingJob || 0}
              link="/employer/jobs?status=pending"
            />
          </div>

          <div className="hidden xl:flex justify-end">
            <div className="flex items-center gap-x-2 text-xs leading-normal text-[#131313] font-medium  cursor-pointer" onClick={() => setChangeColum(!twoColumn)}>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.65 1H2.35C1.60441 1 1 1.60441 1 2.35V17.65C1 18.3956 1.60441 19 2.35 19H17.65C18.3956 19 19 18.3956 19 17.65V2.35C19 1.60441 18.3956 1 17.65 1Z" stroke="black" stroke-width="2" />
                <path d="M10 1V19" stroke="black" stroke-width="2" stroke-linecap="round" />
              </svg>

              {twoColumn ? '1-Column View' : '2-Column View'}
            </div>
          </div>

          <div className="w-full mb-9">
            <div className={`w-full grid gap-6 ${twoColumn ? 'xl:grid-cols-2' : 'grid-cols-1'} overflow-x-hidden`}>
              <div className={`flex flex-col gap-y-4 ${sidebar ? 'lg:max-w-full' : ''} ${notification_list?.length > 0 ? '' : 'xl:col-span-2'}`}>
                <div className="p-6 bg-white rounded-2xl flex flex-col gap-y-2">
                  <div className="flex items-center justify-between">
                    <div>
                      <p className="text-[#131313] text-[13px] md:text-[20px] leading-[1.5] font-semibold">Job post stats</p>
                      <p className="text-[#131313] text-[7px] md:text-[10px] leading-[1.5] font-[300]">This graph shows the number of jobs posted per month</p>
                    </div>

                    <div className={`${chartToggle ? 'bg-white shadow rounded-tl-[4px] rounded-tr-[4px]' : ''} p-2 relative z-10`}>
                      <button className="flex items-center gap-x-1 focus:bg-white focus:border-white focus:outline-white" onClick={chartToggleFunc}>
                        <span className="text-sm md:text-xs text-[#454545] font-semibold leading-normal">Change graph data</span>
                        <svg className={`${chartToggle ? 'hidden' : 'block'}`} width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M3.5 5.75L7 9.25L10.5 5.75" stroke="#7D7D7D" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>

                        <svg className={`${chartToggle ? 'block' : 'hidden'}`} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M3.5 8.75L7 5.25L10.5 8.75" stroke="#7D7D7D" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </button>

                      <div className={`${chartToggle ? 'block h-max' : 'hidden h-[0px]'} transit-all absolute bg-white p-2 pt-0 left-0 w-full shadow justify-start rounded-bl-[4px] rounded-br-[4px]`}>
                        <div className="flex flex-col border-t mt-1 pt-1 border-[#898989] gap-y-1">
                          <button className="text-xs leading-normal text-start text-[#454545] font-normal focus:bg-white focus:border-white focus:outline-white" onClick={() => setType("jobs")}>Job post stats</button>
                          <button className="text-xs leading-normal text-start text-[#454545] font-normal focus:bg-white focus:border-white focus:outline-white" onClick={() => setType("category")}>Job category stats</button>
                          <button className="text-xs leading-normal text-start text-[#454545] font-normal focus:bg-white focus:border-white focus:outline-white" onClick={() => setType("applications")}>Applications Received</button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <DashboardLineChart type={type} timeFrame={timeFrame} />
                </div>
              </div>

              {notification_list !== null && notification_list?.length !== 0 && (
                <div className={`w-full ${sidebar ? 'lg:max-w-full' : ''} bg-white rounded-b-[1rem] h-full overflow`}>
                  <div className="bg-[#FF6633] px-6 flex items-center justify-between gap-x-[10px] py-[10px] rounded-tl-[16px] rounded-tr-[16px]">
                    <div className="flex items-center gap-x-[10px]">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="24" height="24" rx="12" fill="white" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M11 19C11 19.2652 10.8946 19.5196 10.7071 19.7071C10.5196 19.8946 10.2652 20 10 20C9.73478 20 9.48043 19.8946 9.29289 19.7071C9.10536 19.5196 9 19.2652 9 19V8C9 7.73478 9.10536 7.48043 9.29289 7.29289C9.48043 7.10536 9.73478 7 10 7C10.2652 7 10.5196 7.10536 10.7071 7.29289C10.8946 7.48043 11 7.73478 11 8V19ZM7 19C7 19.2652 6.89464 19.5196 6.70711 19.7071C6.51957 19.8946 6.26522 20 6 20C5.73478 20 5.48043 19.8946 5.29289 19.7071C5.10536 19.5196 5 19.2652 5 19V12C5 11.7348 5.10536 11.4804 5.29289 11.2929C5.48043 11.1054 5.73478 11 6 11C6.26522 11 6.51957 11.1054 6.70711 11.2929C6.89464 11.4804 7 11.7348 7 12L7 19ZM13 19V10C13 9.73478 13.1054 9.48043 13.2929 9.29289C13.4804 9.10536 13.7348 9 14 9C14.2652 9 14.5196 9.10536 14.7071 9.29289C14.8946 9.48043 15 9.73478 15 10L15 19C15 19.2652 14.8946 19.5196 14.7071 19.7071C14.5196 19.8946 14.2652 20 14 20C13.7348 20 13.4804 19.8946 13.2929 19.7071C13.1054 19.5196 13 19.2652 13 19ZM17 5C17 4.73478 17.1054 4.48043 17.2929 4.29289C17.4804 4.10536 17.7348 4 18 4C18.2652 4 18.5196 4.10536 18.7071 4.29289C18.8946 4.48043 19 4.73478 19 5V19C19 19.2652 18.8946 19.5196 18.7071 19.7071C18.5196 19.8946 18.2652 20 18 20C17.7348 20 17.4804 19.8946 17.2929 19.7071C17.1054 19.5196 17 19.2652 17 19V5Z" fill="#F24E1E" />
                      </svg>
                      <span className="text-xs md:text-base text-white leading-normal font-medium">Notifications and activities</span>
                    </div>
                    <h3 className="text-xs md:text-base text-white leading-normal font-medium cursor-pointer" onClick={() => navigate("/employer/notifications")}>View all</h3>
                  </div>

                  <div className="flex max-h-[450px] rounded-bl-[16px] rounded-br-[16px] my-1 overflow-y-scroll flex-col">
                    {notification_list?.slice(0, 10)?.map(e => (
                      <DashboardNotificationItem key={e?._id} type={e?.type} createdAt={e?.createdAt} jobTitle={e?.JobId?.jobTitle} custom_name={e?.JobId?.custom_name} notificationId={e?._id} />
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="w-full h-full"></div>
          </div>

          <div className="flex bg-white px-2 py-4 md:p-6 rounded-[1rem] gap-y-4 flex-col">
            <div className="flex sm:items-center flex-col sm:flex-row justify-between py-2">
              <p className="text-[#131313] text-[20px] leading-normal font-medium">
                Recommended Candidates
              </p>
              <div className={`${filterJob ? 'bg-white shadow rounded-tl-[4px] rounded-tr-[4px]' : ''} p-2 relative`}>
                <button className="flex items-center gap-x-1 focus:bg-white focus:border-white focus:outline-white" onClick={filterJobFunc}>
                  <span className="text-sm md:text-xs text-[#454545] font-normal leading-normal">{matchText}</span>
                  <svg className={`${filterJob ? 'hidden' : 'block'}`} width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.5 5.75L7 9.25L10.5 5.75" stroke="#7D7D7D" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>

                  <svg className={`${filterJob ? 'block' : 'hidden'}`} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.5 8.75L7 5.25L10.5 8.75" stroke="#7D7D7D" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>

                </button>
                <div className={`${filterJob ? 'block h-max' : 'hidden h-[0px]'} transit-all absolute  bg-white p-2 pt-0 left-0 w-full shadow justify-start rounded-bl-[4px] rounded-br-[4px]`}>
                  <div className="flex flex-col border-t mt-1 pt-1 border-[#898989] gap-y-1">
                    <button className="text-xs leading-normal text-start text-[#454545] font-normal focus:bg-white focus:border-white focus:outline-white" onClick={getRecommendedEmployeeByLastJobTitle}>Job Matches</button>
                    <button className="text-xs leading-normal text-start text-[#454545] font-normal focus:bg-white focus:border-white focus:outline-white" onClick={getRecommendedEmployeeByCategory}>Industry Matches</button>
                    <button className="text-xs leading-normal text-start text-[#454545] font-normal focus:bg-white focus:border-white focus:outline-white" onClick={getRecommendedEmployeeBySkills}>Skills Matches</button>
                  </div>
                </div>
              </div>
            </div>
            <p className="text-black text-opacity-60 text-base font-medium max-w-[6] mb-3">
              These are the candidates whose skills match your company description. You can switch your recommendations by clicking on <span className="text-[#131313]">Industry Matches’</span> dropdown at the top righ-hand corner of this box.
            </p>
            <div className="flex justify-center">
              <div className="grid grid-cols-1 gap-4 tablet:grid-cols-2 desktop:grid-cols-3">
                <>{tabSwitch == "title" || tabColor === "title" || tabSwitch == "titlet" ?
                  <>
                    {recommend !== null && recommend?.length !== 0 ? (
                      <>
                        {recommend?.slice(0, 14)?.map((data) => (
                          <DashboardCandidateItem
                            key={data?.userId?.profile_id}
                            candidate={data?.Fullname}
                            candidateJob={data?.Job_type}
                            candidateLocation={data?.Address}
                            candidateExperienceLevel={data?.Years_of_experience}
                            candidateBio={data?.About}
                            candidateRating={data?.Rating}
                            candidateSalary="35"
                            profileId={data?.userId?.profile_id}
                            candidateImage={data?.photo}
                          />
                        ))}
                      </>
                    ) : (
                      <h2>
                        Sorry, we presently do not have candidate to recommend.
                      </h2>
                    )}
                  </> :
                  <>
                    {tabSwitch == "category" || tabColor === "category" || tabSwitch == "categoryc" ?

                      <>
                        {recommend_category !== null && recommend_category?.length !== 0 ? (
                          <>
                            {recommend_category?.slice(0, 14)?.map((data) => (
                              <DashboardCandidateItem
                                key={data?.userId?.profile_id}
                                candidate={data?.Fullname}
                                candidateJob={data?.Job_type}
                                candidateLocation={data?.Address}
                                candidateExperienceLevel={data?.Years_of_experience}
                                candidateBio={data?.About}
                                candidateRating={data?.Rating}
                                candidateSalary="35"
                                profileId={data?.userId?.profile_id}
                                candidateImage={data?.photo}
                              />
                            ))}
                          </>
                        ) : (
                          <h2>
                            Sorry, we presently do not have candidate to recommend.
                          </h2>
                        )}
                      </> :
                      <>
                        {tabSwitch == "skills" || tabColor === "skills" ?
                          <>
                            {recommend_skill !== null && recommend_skill?.length !== 0 ? (
                              <>
                                {recommend_skill?.slice(0, 14)?.map((data) => (
                                  <DashboardCandidateItem
                                    key={data?.userId?.profile_id}
                                    candidate={data?.Fullname}
                                    candidateJob={data?.Job_type}
                                    candidateLocation={data?.Address}
                                    candidateExperienceLevel={data?.Years_of_experience}
                                    candidateBio={data?.About}
                                    candidateRating={data?.Rating}
                                    candidateSalary="35"
                                    profileId={data?.userId?.profile_id}
                                    candidateImage={data?.photo}
                                  />
                                ))}
                              </>
                            ) : (
                              <h2>
                                Sorry, we presently do not have candidate to recommend.
                              </h2>
                            )}
                          </> : null}
                      </>

                    } </>
                }</>
              </div>
            </div>
            {/* <PaginationBtn
              totalPages={ourtotalpage}
              currentPage={currentDashboardPage}
              onNextPage={() => {
                if (currentDashboardPage < ourtotalpage) {
                  setCurrentDashboardPage((prev) => prev + 1);
                  // setLoad(true)
                }
              }}
              onPrevPage={() => {
                if (currentDashboardPage > 1) {
                  setCurrentDashboardPage((prev) => prev - 1);
                  // setLoad(true)
                }
              }}
              onSelectPage={(selectedPage) => {
                setCurrentDashboardPage(selectedPage);
              }}
            /> */}

            <div>
              <Link to="/employer/recommended" className="text-[14px] leading-normal font-medium text-[#5843BD] md:text-[20px]">View more recommendations</Link>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      {showModal}
      {load === true ? <LoaderModal text="Getting candidate matches" /> : null}
    </>
  );
}
