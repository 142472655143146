import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import DashboardCandidateItem from "../common/dashboard_candidate_item";
import BackButton from "../common/back_button";
import PaginationBtn from "../common/paginationBtn";
import LoaderModal from "../common/LoaderModal";
import { ToastContainer, toast } from 'react-toastify';
import {
  recommended_candid,
  total_page,
  candidate_category_success,
  candidate_type_success,
  candidate_skill_match_success
} from "../../Redux/ActionCreator";
import {
  getRecommendedCandidate,
  getRecommendedCandidateByCategory,
  getRecommendedCandidateBySkills
} from "../../utils/recommendationAlgo";
import HelmetProvide from "../common/helmet";

export default function DashboardCandidates() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tabSwitch = useSelector((state) => state.candidate_type.message);
  const recommend = useSelector((state) => state.recommended_candidate.message);
  const recommend_category = useSelector((state) => state.candidate_category.message);
  const recommend_skill = useSelector((state) => state.candidate_skill_match.message);
  const totalPages = useSelector((state) => state.total_page.message);

  const [currentPage, setCurrentPage] = useState(1);
  const [filterJob, setFilterJob] = useState(false);
  const [matchText, setMatchText] = useState("Industry Matches");
  const [loading, setLoading] = useState(false);
  const [tabColor, setTabColor] = useState(null);

  useEffect(() => {
    fetchCandidates();
  }, [tabSwitch, currentPage]);

  const fetchCandidates = async () => {
    setLoading(true);
    try {
      let response;
      if (tabSwitch === "title" || tabSwitch === "titlet" || tabColor === "title") {
        response = await getRecommendedCandidate(currentPage);
      } else if (tabSwitch === "category" || tabSwitch === "categoryc" || tabColor === "category") {
        response = await getRecommendedCandidateByCategory(currentPage);
      } else if (tabSwitch === "skills" || tabColor === "skills") {
        response = await getRecommendedCandidateBySkills(currentPage);
      }

      const data = await response.json();

      if (data.success) {
        if (tabSwitch === "title" || tabSwitch === "titlet" || tabColor === "title") {
          dispatch(recommended_candid(data.data.result));
        } else if (tabSwitch === "category" || tabSwitch === "categoryc" || tabColor === "category") {
          dispatch(candidate_category_success(data.data.result));
        } else if (tabSwitch === "skills" || tabColor === "skills") {
          dispatch(candidate_skill_match_success(data.data.result));
        }
        dispatch(total_page(data.data.page_num));
      } else {
        toast.error("Failed to fetch candidates. Please try again.");
      }
    } catch (error) {
      console.error("Error fetching candidates:", error);
      toast.error("An error occurred. Please check your network and try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleFilterJobToggle = () => {
    setFilterJob(!filterJob);
  };

  const getRecommendedEmployeeByLastJobTitle = () => {
    setTabColor("title");
    setMatchText("Job Matches");
    dispatch(candidate_type_success("titlet"));
    setCurrentPage(1);
  };

  const getRecommendedEmployeeByCategory = () => {
    setTabColor("category");
    setMatchText("Industry Matches");
    dispatch(candidate_type_success("categoryc"));
    setCurrentPage(1);
  };

  const getRecommendedEmployeeBySkills = () => {
    setTabColor("skills");
    setMatchText("Skills Matches");
    dispatch(candidate_type_success("skills"));
    setCurrentPage(1);
  };

  const renderCandidates = () => {
    let candidateList;
    if (tabSwitch === "title" || tabColor === "title" || tabSwitch === "titlet") {
      candidateList = recommend;
    } else if (tabSwitch === "category" || tabColor === "category" || tabSwitch === "categoryc") {
      candidateList = recommend_category;
    } else if (tabSwitch === "skills" || tabColor === "skills") {
      candidateList = recommend_skill;
    }

    if (!candidateList || candidateList.length === 0) {
      return <h2>Sorry, we presently do not have candidates to recommend.</h2>;
    }

    return candidateList.map((data) => (
      <DashboardCandidateItem
        key={data?.userId?.profile_id}
        candidate={data?.Fullname}
        candidateJob={data?.Job_type}
        candidateLocation={data?.Address}
        candidateExperienceLevel={data?.Years_of_experience}
        candidateBio={data?.About}
        candidateRating={data?.Rating}
        candidateSalary="35"
        profileId={data?.userId?.profile_id}
        candidateImage={data?.photo}
      />
    ));
  };

  return (
    <>

      <HelmetProvide
      title="Moil | Recommended Candidates"
      description="Explore your employer dashboard on Moil. Manage job listings, connect with talented candidates, and find the best blue-collar talent for your team." />

      <div className="flex justify-center bg-[#FAFAFA] overflow-y-scroll h-calc-150 md:h-calc-70 pt-8 md:px-6 sm:mt-0">
        <div className="px-6 lg:px-0 relative w-full flex flex-col gap-y-2 lg:max-w-[1000px] xl:max-w-[1100px] xllarge:max-w-[1200px]">
          <BackButton onClick={() => navigate(-1)} />

          <div className="flex bg-white px-2 py-4 md:p-6 rounded-[1rem] gap-y-4 flex-col">
            <div className="flex sm:items-center flex-col sm:flex-row justify-between py-2">
              <p className="text-[#131313] text-[20px] leading-normal font-medium">
                Recommended Candidates
              </p>
              <div className={`${filterJob ? 'bg-white shadow rounded-tl-[4px] rounded-tr-[4px]' : ''} p-2 relative`}>
                <button className="flex items-center gap-x-1 focus:bg-white focus:border-white focus:outline-white" onClick={handleFilterJobToggle}>
                  <span className="text-sm md:text-xs text-[#454545] font-normal leading-normal">{matchText}</span>
                  <svg className={`${filterJob ? 'hidden' : 'block'}`} width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.5 5.75L7 9.25L10.5 5.75" stroke="#7D7D7D" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  <svg className={`${filterJob ? 'block' : 'hidden'}`} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.5 8.75L7 5.25L10.5 8.75" stroke="#7D7D7D" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </button>
                <div className={`${filterJob ? 'block h-max' : 'hidden h-[0px]'} transit-all absolute  bg-white p-2 pt-0 left-0 w-full shadow justify-start rounded-bl-[4px] rounded-br-[4px]`}>
                  <div className="flex flex-col border-t mt-1 pt-1 border-[#898989] gap-y-1">
                    <button className="text-xs leading-normal text-start text-[#454545] font-normal focus:bg-white focus:border-white focus:outline-white" onClick={getRecommendedEmployeeByLastJobTitle}>Job Matches</button>
                    <button className="text-xs leading-normal text-start text-[#454545] font-normal focus:bg-white focus:border-white focus:outline-white" onClick={getRecommendedEmployeeByCategory}>Industry Matches</button>
                    <button className="text-xs leading-normal text-start text-[#454545] font-normal focus:bg-white focus:border-white focus:outline-white" onClick={getRecommendedEmployeeBySkills}>Skills Matches</button>
                  </div>
                </div>
              </div>
            </div>
            <p className="text-black text-opacity-60 text-base font-medium max-w-[6] mb-3">
              These are the candidates whose skills match your company description. You can switch your recommendations by clicking on <span className="text-[#131313]">Industry Matches'</span> dropdown at the top right-hand corner of this box.
            </p>
            <div className="flex justify-center">
              <div className="grid grid-cols-1 gap-4 tablet:grid-cols-2 desktop:grid-cols-3">
                {renderCandidates()}
              </div>
            </div>
            <PaginationBtn
              totalPages={totalPages}
              currentPage={currentPage}
              onNextPage={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
              onPrevPage={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
              onSelectPage={setCurrentPage}
            />
          </div>
        </div>
        <ToastContainer />
        {loading && <LoaderModal text="Loading candidates..." />}
      </div>

      <ToastContainer />
    </>
  );
}