import { Link } from "react-router-dom";
import PreviewPricingItem from "../preview_pricing_item";
import { useState } from "react";
import { useSelector } from "react-redux";
import BackButton from "../back_button";
import { Percent } from "@mui/icons-material";

export default function PricingSection({ operationType, handleSubscribe, close, display, hide }) {

  const employer = useSelector(e => e.employer.message);

  const [preShow, setPreShow] = useState("monthly");

  const pricingPlans = [
    {
      plan: "BASIC",
      benefits: [
        "Ideal for very small businesses or those with infrequent hiring needs.",
        "1 job posting per month.",
        "Basic skill tests included.",
        "Basic performance analytics.",
        "Email support."
      ],
      price: {
        monthly: 40,
        annually: 408
      },
      cta: operationType == "upgrade" 
        ? "Upgrade Plan" : operationType == "cancel" 
        ? "Cancel Plan" : operationType == "subscribe" 
        ? (employer?.plan == "basic_monthly" && preShow == "monthly") || (employer?.plan == "basic_yearly" && preShow == "annually") ? "Current Plan" : employer?.plan == undefined ? "Sign Up Now" : (employer?.plan !== "basic_monthly" && employer?.plan.includes("monthly") && preShow == "monthly") || (employer?.plan !== "basic_yearly"  && employer?.plan.includes("yearly") && preShow == "annually") ? "Downgrade Plan" : "Upgrade Plan" : "Sign Up Now",
      operationType: operationType,
      percentOff: '15%'
    },
    {
      plan: "STANDARD",
      benefits: [
        "Suitable for small to medium-sized businesses with more consistent hiring needs.",
        "Up to 3 job postings per month.",
        "Basic skill tests included.",
        "2 featured listings per month.",
        "Advanced performance analytics.",
        "Priority email support."
      ],
      price: {
        monthly: 75,
        annually: 720
      },
      cta: operationType == "upgrade" 
      ? "Upgrade Plan" : operationType == "cancel" 
      ? "Cancel Plan" : operationType == "subscribe" 
      ? (employer?.plan == "standard_monthly" && preShow == "monthly") || (employer?.plan == "standard_yearly" && preShow == "annually") ? "Current Plan" : employer?.plan == undefined ? "Start Free 14-day Trial" : (employer?.plan == "professional_monthly" && employer?.plan.includes("monthly") && preShow == "monthly") || (employer?.plan == "professional_yearly" && employer?.plan.includes("yearly") && preShow == "annually") ? "Downgrade Plan" : "Upgrade Plan" : "Sign Up Now",
      operationType: operationType,
      percentOff: '20%'
    },
    {
      plan: "PROFESSIONAL",
      benefits: [
        "Ideal for medium to large-sized businesses with regular hiring requirements.",
        "Unlimited job postings.",
        "Basic skill tests included.",
        "10 featured listings per month.",
        "Premium analytics including applicant tracking and engagement metrics.",
        "Priority email and phone support."
      ],
      price: {
        monthly: 150,
        annually: 1350
      },
      cta: operationType == "upgrade" 
      ? "Upgrade Plan" : operationType == "cancel" 
      ? "Cancel Plan" : operationType == "subscribe" 
      ? (employer?.plan == "professional_monthly" && preShow == "monthly") || (employer?.plan == "professional_yearly" && preShow == "annually") ? "Current Plan" : employer?.plan == undefined ? "Sign Up Now" : "Upgrade Plan" : "Sign Up Now",
      operationType: operationType,
      percentOff: '25%'
    }
  ];


  return (
    <section className="relative bg-[#fff] rounded-lg" >
      <div className={`w-full ${operationType === "upgrade" ? "lg:px-2 xl:px-4 py-6 xl:py-8" : "px-6 xl:px-8 py-6 xl:py-8"} lg:max-w-[1000px] mx-auto`}>
        {
          hide == true ? null : <BackButton onClick={() => close(operationType)} />
        }
        <div className={`flex flex-col  ${operationType === "upgrade" ? "" : "gap-y-4"}`}>
          {operationType !== 'upgrade' && (
            <div className="flex flex-col gap-y-2">
              <p className="text-[20px]  text-center leading-[1.75] font-semibold text-black">
                Subscription Plans
              </p>
              <p className="text-base leading-[1.4] text-center font-normal text-black">
                Here is a preview of our subscription plans. To more details, click on "View all details".
              </p>
            </div>
          )}
          <div className={`flex flex-col gap-y-2`}>
            <div className="flex gap-y-4 flex-col md:flex-row item-center justify-center gap-x-4">
              <button onClick={() => setPreShow("monthly")} className={`${preShow === "monthly" ? "bg-[#FF6633] text-white text-[#252430]" : "border_05 border-[#858BA0]"}  py-[10px] px-[20px] rounded-[22px] text-center leading-normal text-base`}>Monthly Plans</button>
              <button onClick={() => setPreShow("annually")} className={`${preShow === "annually" ? "bg-[#FF6633] text-white text-[#252430]" : "border_05 border-[#858BA0]"}  py-[10px] px-[20px] rounded-[24px] text-center leading-normal text-base`}>See Annual Plans</button>
            </div>
            <p className="text-[#FF6633] text-sm leading-normal font-medium text-center">Save up to 25% when you purchase an annual plan.</p>
          </div>
          <div className={`flex flex-col p-4 md:flex-row md:items-stretch items-center ${operationType === "upgrade" ? "flex-col" : "gap-2 bg-[#FAFAFA]"}`}>
            {
              pricingPlans.map((planItem, index) => {
                return (
                  <PreviewPricingItem percentOff={planItem.percentOff} preShow={preShow} key={index} plan={planItem.plan} benefits={planItem.benefits} cta={planItem.cta} price={planItem.price} click={handleSubscribe} operationType={planItem.operationType} display={display} />
                )
              })
            }
          </div>

          {operationType !== 'subscribe' ? <div className="self-center flex gap-x-2 items-center">
            <Link className="text-base leading-normal  flex justify-center items-center p-2 dash-link-grad text-center text-white font-medium rounded-lg">
              View all details
            </Link>

            <Link className="text-base leading-normal gap-x-4 flex justify-center items-center p-2 bg-[#F9F4FD] text-center text-[#000000DE] font-medium rounded-md border border-[#E6E9F5]">
              See FAQs
              <span className="p-1 rounded-[3px] bg-[#5843BD]">
                <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.98012 13.0184V0.984375H8.02262V13.0184H4.98012ZM0.484375 8.52262V5.48013H12.5184V8.52262H0.484375Z" fill="#E6E9F5" />
                </svg>
              </span>
            </Link>
          </div> : null}
        </div>
      </div>
    </section>
  )
}