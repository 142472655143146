import React, { useContext, useEffect, useState } from "react";
import {
  JobDurationSvg,
  JobTitleSvg,
  JobTypeSvg,
  LocationSvg,
} from "../../common/svgs/svgs";
import PreviewButton from "../../common/preview_components/preview_button";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  create_job,
  dispatchJobDetail,
  updateStripeCredentials,
  ApplyForAJobPublic,
  establishChat,
  new_job_match_loading,
  returnHome,
  getChatInformation,
  logOut,
  job_doc_loading,
  job_success,
  SiteErrorLoading
} from "../../../Redux/ActionCreator";
import { ToastContainer, toast } from "react-toastify";
import JobPostSuccessModal from "./job_post_success";
import { createPortal } from "react-dom";
import HelmetProvide from "../../common/helmet";
import StripeComponent from "../../Payment/StripeContainer";
import PaymentForm from "../../Payment/PaymentForm";
import { useAnalytics } from "../../../context/AnalyticsContext";
import { handleKeyDown } from "../../../utils/keydown/keydown";
import { baseURL, baseURL1 } from "../../../Redux/baseURL";
import LoaderModal from "../../common/LoaderModal";
import { convertStringToArray, correctJobSkills, convertHtmlBrToCommaArray } from "../../../utils/stringToArray/stringToArray";
import { getTwoFromServerCache, saveIntoServerCache, getOneFromServerCache } from "../../../utils/saveIntoCache";
import ReactHtmlParser from 'react-html-parser';
import Tab from "../../common/tab/tab";
import SkillTestPrevItem from "../../common/skill_test_prev_item";


export default function JobPostPreviewDetails(props) {
  const analytics = useAnalytics();
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const chat_info = useSelector(e => e.chat_info.message);
  const employer = useSelector((e) => e.employer.message);
  const userInfo = useSelector((prev) => prev.userInfo.user);
  const posting_stat = useSelector((e) => e.posting_stat);
  const [payment, setPayment] = useState(false);
  const job_posting_end_date = employer?.stripe_jobposting_end_date;
  const job_posting_credit = employer?.stripe_jobposting_credit;
  const [verificationLoad, setVerificationLoad] = useState(false);
  const jobDetails = useSelector((info) => info.jobDetails.jobDetails);
  const jobsPostedState = useSelector(store => store.jobs_posted);
  const publicInfo = useSelector(e => e.public);
  const publicProfile = publicInfo.message?.profile;
  const publicResumes = useSelector((prev) => prev.publicResume);
  const lastResume = publicResumes.message?.length > 0 ? publicResumes.message[publicResumes.message?.length - 1] : []
  const matched = useSelector(e => e.newjobpost.message);
  let errorMessages = useSelector((e) => e.errors.errMess);

  const complete = new URLSearchParams(window.location.search).get("complete");
  const pw = new URLSearchParams(window.location.search).get("pw");
  const jobName = new URLSearchParams(window.location.search).get("jN");

  let [skillTest, setSkillTest] = useState(null);
  let [ allAnswer, setAllAnswers ] = useState([]);


  const [hide, setHide] = useState(false);
  useEffect(() => {
    if (pw !== null && pw == "true") {
      setHide(true);
    }
  }, [pw]);

  // chat index of user
  let index = chat_info?.findIndex(entry => {
    return entry?.chatIds?.some(chat => chat.employeeId?._id === publicProfile?._id);
  })

  useEffect(() => {
    if (jobDetails == null && complete == null && pw == null) {
      let cacheInfo = getTwoFromServerCache("jobPostPG1", "jobPostPG2")
      cacheInfo.then(res => res.json())
        .then(res => {
          console.log("server cache value", res);
          if (res.success === true) {
            if ((res.data.value1 !== null && res.data.value1 !== undefined) && (res.data.value2 !== null && res.data.value2 !== undefined)) {
              let resObj1 = JSON.parse(res.data.value1);
              let resObj2 = JSON.parse(res.data.value2);
              dispatch(dispatchJobDetail({ ...resObj1, ...resObj2 }));
            } else {
              navigate("/employer/jobs")
            }
            console.log("Done");
          } else if (res.success === false && res.message == "Unauthorized") {
            dispatch(returnHome(res.status));
          }
        })
    }
  }, []);

  useEffect(() => {
    if (errorMessages == "You already exhaust your credit for the month. Please renew your plan to post more jobs") {
      toast.error(errorMessages, {
        position: toast.POSITION.TOP_CENTER,
      });
      setTimeout(() => {
        navigate("/employer/subscribe");
      }, 50);
    } else if (errorMessages == "Job posted successful.") {
      setVerificationLoad(true);
      openModal();
    } else {
      toast.error(errorMessages, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    dispatch(SiteErrorLoading(true))
  }, [errorMessages]);

  // const paymentStatus = new URLSearchParams(window.location.search).get("payment");
  // useEffect(() => {
  //   if (paymentStatus == "hd") {
  //     setPayment(false);
  //   }
  // }, [paymentStatus])

  useEffect(() => {
    if (posting_stat.message !== null && typeof posting_stat.message == "object" && posting_stat.message._id !== undefined && posting_stat.message._id !== null && jobDetails == null) {
      let job = [posting_stat.message];
      const data = {
        _id: job[0]?._id,
        display: job[0]?.display,
        name: job[0]?.companyName,
        no_of_employees: job[0]?.companyEmployeeRange,
        type_of_business: job[0]?.companyBuinsessType,
        about: job[0]?.companyAbout,
        profile_id: userInfo?.profile_id,
        photo: job[0]?.logo,
        jobTitle: job[0]?.jobTitle,
        employmentType: job[0]?.jobType?.toUpperCase(),
        Category: job[0]?.jobCategory,
        Description: job[0]?.jobDescription,
        Responsibilities: job[0]?.responsibilities,
        Benefits: job[0]?.Benefits,
        Skills: job[0]?.skills,
        paymentPlan: job[0]?.paymentPlan?.toUpperCase(),
        compensation: job[0]?.compensation,
        duration: job[0]?.applicationDeadline,
        verify: job[0]?.verify,
        city: job[0]?.location?.city,
        state: job[0]?.location?.state,
        zipcode: job[0]?.location?.zipCode,
        skillTest: job[0]?.skillTest
      };
      setSkillTest(job[0]?.skillTest?.skillTestQuestions);
      dispatch(dispatchJobDetail(data));
    }
  }, [posting_stat.message])

  // get the chat information
  useEffect(() => {
    dispatch(getChatInformation(userInfo?.profile_id));
  }, [])

  useEffect(() => {
    const sessionId = new URLSearchParams(window.location.search).get("session_id");
    const customerId = new URLSearchParams(window.location.search).get("customer");
    // const paymentStatus = new URLSearchParams(window.location.search).get("payment");
    const complete = new URLSearchParams(window.location.search).get("complete");
    const jobName = new URLSearchParams(window.location.search).get("jN");
    console.log("complete", complete);

    if (complete == "false" || pw == "true") {
      // get job from jobsPostedState to be paid for
      let job = jobsPostedState?.message?.filter(e => e.custom_name == jobName);
      console.log("job", job);
      if (job?.length > 0) {
        const data = {
          _id: job[0]?._id,
          display: job[0]?.display,
          name: job[0].companyName,
          no_of_employees: job[0]?.companyEmployeeRange,
          type_of_business: job[0]?.companyBuinsessType,
          about: job[0].companyAbout,
          profile_id: userInfo.profile_id,
          photo: job[0].logo,
          jobTitle: job[0]?.jobTitle,
          employmentType: job[0]?.jobType?.toUpperCase(),
          Category: job[0]?.jobCategory,
          Description: job[0]?.jobDescription,
          Responsibilities: job[0]?.responsibilities,
          Benefits: job[0]?.Benefits,
          Skills: job[0]?.skills,
          paymentPlan: job[0]?.paymentPlan?.toUpperCase(),
          compensation: job[0]?.compensation,
          duration: job[0]?.applicationDeadline,
          verify: job[0]?.verify,
          city: job[0]?.location.city,
          state: job[0]?.location.state,
          zipcode: job[0]?.location.zipCode,
          skillTest: job[0]?.skillTest
        };
        setSkillTest(job[0]?.skillTest?.skillTestQuestions);
        dispatch(dispatchJobDetail(data));
      }
    }

    // if (paymentStatus !== null && sessionId !== null && customerId !== null) {
    //   setVerificationLoad(true);
    //   // handleSubmit(); // call function that verifies the payment for the alreay posted job.
    // }
    // handlePaymentverification();
    // if (paymentStatus === "false") {
    //   setPayment(true);
    // }
  }, []);

  console.log("skillTest", skillTest);

  useEffect(() => {
    // check if jobDetails.Responsibilities is an array or string
    // if string, convert to array, if the seperator "." is present withing the string.
    console.log("JobDetails ==>>", jobDetails);
    if (typeof jobDetails?.Responsibilities == "string"  ) {
      // covert to array
      let newJobDetails = jobDetails;
      newJobDetails.Responsibilities = convertHtmlBrToCommaArray(newJobDetails?.Responsibilities);
      console.log("Respons ==>::", newJobDetails.Responsibilities)
      dispatch(dispatchJobDetail(newJobDetails));
    }

    if (typeof jobDetails?.Benefits == "string") {
      // covert to array
      let newJobDetails = jobDetails;
      newJobDetails.Benefits = convertHtmlBrToCommaArray(newJobDetails?.Benefits);
      console.log("Respons ==>::", newJobDetails.Benefits);
      dispatch(dispatchJobDetail(newJobDetails));
    }
  }, [])

  // const handlePaymentverification = async () => {
  //   const sessionId = new URLSearchParams(window.location.search).get("session_id");
  //   const customerId = new URLSearchParams(window.location.search).get("customer");
  //   // const paymentStatus = new URLSearchParams(window.location.search).get("payment");
  //   const paymentType = new URLSearchParams(window.location.search).get("paymentType");
  //   const paymentDate = new URLSearchParams(window.location.search).get("paymentDate");
  //   const job_posting_credit = employer?.stripe_jobposting_credit;
  //   const job_posting_end_date = employer?.stripe_jobposting_end_date;

  //   fetch(baseURL + "/api/stripe/paymentConfirmation", {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       // Authorization: "Bearer " + localStorage.getItem("token"),
  //     },
  //     credentials: "include"
  //   }).then(resp => resp.json())
  //     .then(resp => {
  //       console.log("Verification response", resp)
  //       if (resp.success == true && resp.data.status == "complete") {
  //         setVerificationLoad(false);
  //         if (resp.data.paymentType === "perpay") {
  //           dispatch(updateStripeCredentials(customerId, sessionId, "", 0));
  //         } else if (resp.data.paymentType === "tenpay") {
  //           if (!job_posting_credit) {
  //             dispatch(updateStripeCredentials(customerId, sessionId, "", 9));
  //           }
  //         } else if (resp.data.paymentType === "sub") {
  //           let paymentDay = new Date(paymentDate);
  //           let duedate = new Date(paymentDay);
  //           duedate.setDate(paymentDay.getDate() + 365);
  //           if (!job_posting_end_date) {
  //             dispatch(updateStripeCredentials(customerId, sessionId, duedate.toISOString(), Infinity));
  //           }
  //         }
  //         // else if (resp.data.paymentType === "Test") {

  //         // }
  //         matched ? handleEstablishMessage(employer, jobDetails.jobTitle, publicProfile, lastResume, jobName) : openModal();
  //         // do something here to show the job.
  //       } else if (resp.statusCode == undefined) {
  //         setVerificationLoad(false);
  //         if (resp.message == "No token provided") {
  //           setTimeout(() => {
  //             toast.warning("Session expired", {
  //               position: toast.POSITION.TOP_RIGHT,
  //             });
  //           }, 50);
  //           dispatch(logOut());
  //           window.location.assign(baseURL1)
  //         }
  //         // do something here to show that the payment is not successful.
  //         // do nothing because there's no payment session yet!!!
  //         // setTimeout(() => {
  //         //   toast.warning("Make your payment now", {
  //         //     position: toast.POSITION.TOP_RIGHT,
  //         //   });
  //         // }, 50);
  //       }
  //     }).catch(err => {
  //       console.log("payment verification error", err);
  //     })
  // }


  const handleFadePayment = () => {
    if (hide == true) {
      navigate(`/employer/jobs/${jobName}`)
    } else {
      // if (paymentStatus !== null) {
      //   navigate("/employer/create-job/preview")
      // }
      if (job_posting_credit) {
        // dispatch(updateStripeCredentials(job_posting_credit - 1)); // do this inside the create job post api
        handleSubmit();
        matched ? handleEstablishMessage(employer, jobDetails.jobTitle, publicProfile, lastResume, jobName) : null; //openModal()
      } else if (new Date(job_posting_end_date) > new Date()) {
        handleSubmit();
        matched ? handleEstablishMessage(employer, jobDetails.jobTitle, publicProfile, lastResume, jobName) : null; // openModal()
      } else {
        handleSubmit();
        // setPayment(true);
        dispatch(job_doc_loading(true));
      }
    }
  }
  const handleEdit = () => {
    dispatch(job_success(jobDetails))
    if (props?.handleStepChangeBackward && skillTest) {
      props.handleStepChangeBackward();
    } else if (props?.handleStepChangeBackward && !skillTest) {
      props.handleStepChangeBackward();
      props.handleStepChangeBackward();
      props.handleStepChangeBackward();
    } 
    navigate("/employer/create-job");
  };
  const gpt_help = useSelector((e) => e.job);
  // const respon = gpt_help.message?.Responsibilities.split(",");
  // const skill = gpt_help.message?.Skills.split(",");

  // This function check if the modalOpen is true and toggle it to false when implemented. This action will close the modal in the children components
  const closeModal = () => {
    if (modalOpen) {
      setModalOpen(!modalOpen);
      navigate("/employer/jobs");
    }
  };

  // This function check if the modalOpen is false and toggle it to true when implemented, This action will open the modal in the children components
  const openModal = () => {
    if (!modalOpen) {
      setModalOpen(!modalOpen);
    }
  };

  console.log(posting_stat);

  //  useEffect(() => {
  //    let month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  //    const d = jobDetails.duration.split("/");
  //    console.log("d", d)
  //    let date = `${d[0]}, ${month[Number(d[1])]} ${d[2]}`;
  //    console.log("Date", date)
  //    jobDetails.duration = date;
  //    }, [jobDetails])
  // const jobDescription = useSelector(
  //   (info) => info.JobDescription.jobDescription
  // );

  // console.log(jobDescription);
  const handleEstablishMessage = (profile, jobTitle, publicProfile, lastResume, jobName) => {
    dispatch(ApplyForAJobPublic(jobName, lastResume._id, publicInfo.message?.user?._id));
    dispatch(establishChat(profile?._id, publicProfile?._id, jobTitle, lastResume._id, jobName)); // function to establish chat;
    if (index >= 0) {
      navigate(`/employer/inbox/${publicProfile?.Fullname}?i=${index}`);
    } else {
      navigate(`/employer/inbox`);
    }
    dispatch(new_job_match_loading());
  }
  console.log(jobDetails);

  const handleSubmit = (e) => {
    if (Array.isArray(jobDetails?.Skills)) {
      for (let i = 0; i <= jobDetails?.Skills.length - 1; i++) {
        if (typeof jobDetails.Skills[i] === "object") {
          jobDetails.Skills[i] = jobDetails?.Skills[i].value;
        } else if (typeof jobDetails.Skills[i] === "string") {
          jobDetails.Skills[i] = jobDetails?.Skills[i];
        }
      }
    } else if (typeof jobDetails?.Skills === "string") {
      let sk = jobDetails?.Skills.split(",");
      jobDetails.Skills = sk;
    }

    let display;
    if (job_posting_credit !== undefined && job_posting_credit !== 0) {
      display = true;
    } else if (job_posting_end_date !== undefined && job_posting_end_date !== "") {
      if (new Date(job_posting_end_date) > new Date()) {
        display = true;
      } else {
        display = false;
      }
    } else {
      display = false;
    }
    let id;
    if (jobDetails._id !== undefined) {
      id = jobDetails._id;
    } else {
      id = "";
    }

    const formData = {
      _id: id,
      availability: true,
      indeed: false,
      indeedLink: "",
      posted_on: "",
      companyName: employer.name,
      companyEmployeeRange: employer?.no_of_employees,
      companyBuinsessType: employer?.type_of_business,
      companyAbout: employer.about,
      profile_id: userInfo.profile_id,
      logo: employer.photo,
      jobTitle: jobDetails.jobTitle,
      jobType: jobDetails.employmentType.toUpperCase(),
      jobCategory: jobDetails.Category,
      jobDescription: jobDetails.Description,
      responsibilities: jobDetails.Responsibilities,
      Benefits: jobDetails.Benefits,
      skills: jobDetails.Skills,
      paymentPlan: jobDetails.paymentPlan.toUpperCase(),
      compensation: jobDetails.compensation,
      applicationDeadline: jobDetails.duration,
      verify: jobDetails.verify,
      location: {
        city: jobDetails.city,
        state: jobDetails.state,
        zipcode: jobDetails.zipCode,
      },
      skillTest: skillTest,
    };
    console.log("data", formData);
    setVerificationLoad(true);
    if (posting_stat.message === null) {
      dispatch(create_job(formData)); // dispatch(create_job(posting_stat.message));
    }
    // openModal();
    // setPayment(false);
  };

  // const handlePublishBtn = () => {
  //   analytics.track("Publish button", {
  //     Type: "Employer",
  //     time: new Date(),
  //     Email: employer?.email,
  //     Company_name: employer?.name,
  //     profileId: employer?._id,
  //     userId: employer?.userId
  //   });
  //   setModalOpen(true);
  // }

  // const makePayment = payment ? <>{!employer.job_posting_credit === false || new Date(job_posting_end_date) > new Date() ? handleSubmit() : createPortal(<StripeComponent />, document.getElementById("payment"))}</> : null

  const modal = modalOpen
    ? createPortal(
      <div onClick={closeModal} className="top-0 left-0 fixed bottom-0 right-0 z-10 h-screen flex justify-center items-center bg-black bg-opacity-70 backdrop-blur-[2px] ">
        <JobPostSuccessModal
          closeModal={closeModal}
          setModalOpen={setModalOpen}
        />
      </div>,
      document.getElementById("modal")
    )
    : null;

  useEffect(() => {
    const getSkillTest = async () => {
      try {
        return fetch(baseURL + `/api/test/getSkillTest/${jobDetails?.jobTitle}/${jobDetails?.skillTest}`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include"
      }).then(res => res.json())
        .then(data => {
          if (data.message == "No token provided" || data.message == "Unauthorized") {
            setTimeout(() => {
              toast.error("Session Expired! Please Login Again", {
                position: toast.POSITION.TOP_CENTER,
              });
              dispatch(logOut());
              window.location.assign(baseURL1);
            }, 50)
          }
          console.log("data", data.data);
          let resp = JSON.parse(data.data);
          if (resp !== null ) {
            setSkillTest(resp);
            // setAllAnswers(prev => resp?.map((item) => item.answer));
          }
        })
      } catch (error) {
        console.log("error", error);
        throw error;
      }
    }
    if ( jobDetails?.skillTest === undefined || typeof jobDetails?.skillTest !== "object") {
      getSkillTest();
    }
  }, []);

    // const plumbingSkillTest = [
    //   {
    //     question: "Which tool is most commonly used to remove a blockage in a drain?",
    //     options: ["Pipe wrench", "Plunger", "Pipe cutter", "Basin wrench"],
    //     answer: "Plunger"
    //   },
    //   {
    //     question: "What is the purpose of a P-trap in plumbing?",
    //     options: [
    //       "To prevent water hammer",
    //       "To prevent sewer gases from entering the building",
    //       "To increase water pressure",
    //       "To allow air into the plumbing system"
    //     ],
    //     answer: "To prevent sewer gases from entering the building"
    //   },
    //   {
    //     question: "Which type of pipe is most commonly used for residential water supply lines?",
    //     options: ["PVC", "Copper", "Galvanized steel", "Cast iron"],
    //     answer: "Copper"
    //   },
    //   {
    //     question: "What is the main cause of water hammer in plumbing systems?",
    //     options: [
    //       "Incorrect pipe size",
    //       "High water pressure",
    //       "Loose fittings",
    //       "Air in the pipes"
    //     ],
    //     answer: "High water pressure"
    //   },
    //   {
    //     question: "What is the correct way to fix a leaking compression fitting?",
    //     options: [
    //       "Tighten the compression nut",
    //       "Replace the ferrule",
    //       "Apply thread seal tape",
    //       "All of the above"
    //     ],
    //     answer: "All of the above"
    //   },
    //   {
    //     question: "What is a common method to prevent pipes from freezing in cold weather?",
    //     options: [
    //       "Insulating the pipes",
    //       "Using copper pipes",
    //       "Lowering the water pressure",
    //       "Draining the water system"
    //     ],
    //     answer: "Insulating the pipes"
    //   },
    //   {
    //     question: "Which type of valve is used to control the flow of water in a plumbing system?",
    //     options: ["Ball valve", "Check valve", "Relief valve", "Float valve"],
    //     answer: "Ball valve"
    //   },
    //   {
    //     question: "What is the purpose of a backflow preventer in a plumbing system?",
    //     options: [
    //       "To increase water pressure",
    //       "To prevent contaminated water from flowing back into the clean water supply",
    //       "To reduce water usage",
    //       "To prevent pipe corrosion"
    //     ],
    //     answer: "To prevent contaminated water from flowing back into the clean water supply"
    //   },
    //   {
    //     question: "Which tool is typically used to cut PVC pipe?",
    //     options: ["Hacksaw", "Pipe cutter", "Reciprocating saw", "Angle grinder"],
    //     answer: "Pipe cutter"
    //   },
    //   {
    //     question: "What does the term 'sweating a pipe' refer to in plumbing?",
    //     options: [
    //       "Applying thread sealant to a pipe joint",
    //       "Soldering a copper pipe",
    //       "Cleaning a pipe before installation",
    //       "Using a wrench to tighten a pipe fitting"
    //     ],
    //     answer: "Soldering a copper pipe"
    //   }
    // ];

  return (
    <>
      <HelmetProvide
        title="Moil | Preview Job"
        description="Preview and manage job listings on Moil. Connect with talented blue-collar workers and find the best candidates for your team."
      />
      {jobDetails == null ? null :
        <div className="px-3 lg:pl-6 py-8 w-full flex justify-center h-calc-150 md:h-calc-70 overflow-y-scroll"
          onCut={(e) => e.preventDefault()}
          onCopy={(e) => e.preventDefault()}
          onKeyDown={handleKeyDown}>
          <div className="h-max w-full flex justify-center">
            <div className="flex w-full max-w-[900px] xtraxl:max-w-[1200px] flex-col gap-y-6 justify-center">

              <div className="tabarea justify-start items-center gap-6 inline-flex w-full flex-shrink-0  overflow-hidden">
                <Tab
                  // label="Personal Information"
                  label="Job Details"
                  current={false}
                  completed={true}
                  num="1"
                />
                <Tab num="2" label="Employment Details" current={false} completed={true} />
                <Tab num="3" label="Skill Test" current={false} completed={true} />
              </div>

              <div className="flex text-[18px] flex-col">
                <p class="font-semibold leading-normal text-left text-[#0D0D0D]">
                  Preview your job before publishing it.
                </p>
                <p class="font-normal leading-normal text-left text-[#0D0D0D]">
                  Use the ‘Edit Job’ button at the bottom to make changes on the form.
                </p>
              </div>


              <div className="w-full flex-col justify-start items-start gap-y-6 inline-flex">
                <div className="w-full px-4 sm:px-12  py-8 bg-[#5843BD] rounded-lg shadow border border-[#5843BD]  flex-col justify-start items-start gap-8 flex">
                  <div className="flex flex-col gap-y-4">
                    <div className="w-full flex-col justify-start items-start gap-4 flex">
                      <p className="text-white text-xl sm:text-2xl font-semibold font-['Poppins']">
                        {/*  {language["profe"]} */} {jobDetails?.jobTitle}
                      </p>
                      <div className="w-full justify-start items-start gap-4 flex flex-wrap">
                        <div className="flex gap-x-2 flex-none">
                          <JobTitleSvg />
                          <p className="text-[#FFFFFFB3] leading-normal text-opacity-80 text-sm font-medium">
                            {jobDetails?.jobTitle}
                          </p>
                        </div>
                        <div className="flex gap-x-2 flex-none">
                          <JobTypeSvg />
                          <p className="text-[#FFFFFFB3] leading-normal text-opacity-80 text-sm font-medium">
                            {jobDetails?.employmentType}
                          </p>
                        </div>
                        <div className="flex gap-x-2 flex-none">
                          <LocationSvg />
                          <p className="text-[#FFFFFFB3] leading-normal text-opacity-80 text-sm font-medium">
                            {`${jobDetails?.city}, ${jobDetails?.state}.`}
                          </p>
                        </div>


                        <div className="flex flex-wrap gap-4">
                          <div className="flex gap-x-2">
                            <JobDurationSvg />
                            <p className="whitespace-normal">
                              <span className="text-[#FFFFFFB3] leading-normal text-opacity-80 text-sm font-medium">
                                Recruiting until{" "}
                              </span>
                              <span className="text-white text-sm font-semibold">
                                {jobDetails?.duration}
                              </span>
                            </p>
                          </div>
                          <div className="flex gap-x-2">
                            <JobDurationSvg />
                            <p className="whitespace-normal">
                              <span className="text-[#FFFFFFB3] leading-normal text-opacity-80 text-sm font-medium">
                                Verify type:{" "}
                              </span>
                              <span className="text-indigo-700 text-sm font-semibold">
                                {jobDetails?.verify}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="px-4 sm:px-12 py-8 bg-white bg-opacity-80 rounded-lg shadow border border-indigo-400 border-opacity-30 flex-col justify-start items-start gap-8 flex">
                  <div className="justify-start items-center flex flex-col gap-y-6 w-full inline-flex">
                    <div className="self-stretch flex-col justify-start items-start gap-4 inline-flex">
                      <div className="text-indigo-700 text-xl sm:text-2xl font-semibold font-['Poppins']">
                        Job Details
                      </div>
                    </div>

                    <hr className="bg-[#777DEA26] w-full h-[1.35px]" />
                  </div>

                  <div className="self-stretch justify-start items-start inline-flex">
                    <div className="justify-start items-start gap-2 flex">
                      <div className="flex-col justify-start items-start gap-2 inline-flex">
                        <div className="text-indigo-700 text-sm font-semibold font-['Poppins']">
                          Job Description
                        </div>
                        <div className="self-stretch text-justify text-gray-600 text-sm font-medium font-['Poppins'] w-full flex-wrap">
                          {/* {gpt_help.message?.Description} */}
                          {ReactHtmlParser(jobDetails?.Description)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="self-stretch justify-start items-start inline-flex">
                    <div className="grow shrink basis-0 justify-start items-start gap-2 flex">
                      <div className="grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex w-full flex-wrap">
                        <p className="text-indigo-700 text-sm font-semibold font-['Poppins']">
                          Key Responsibilities
                        </p>
                        <ul className="self-stretch text-justify text-gray-600 text-sm font-medium list-disc pl-4">
                          {jobDetails?.Responsibilities !== undefined ?
                            <>
                              {Array.isArray(jobDetails?.Responsibilities) ? (
                                <>
                                  {jobDetails?.Responsibilities?.map(
                                    (data, key) => (
                                      <li key={key}>{ReactHtmlParser(data)}</li>
                                    )
                                  )}
                                </>
                              ) : (
                                <>
                                  {jobDetails?.Responsibilities.split(",")?.map(
                                    (item, index) => (
                                      <li key={index}>{ReactHtmlParser(index)}</li>
                                    )
                                  )}
                                </>
                              )}
                            </> : null}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="self-stretch justify-start items-start inline-flex">
                    <div className="grow shrink basis-0 flex-col justify-start items-start gap-4 inline-flex">
                      <p className="text-indigo-700 text-sm font-semibold font-['Poppins']">
                        Required Skills
                      </p>
                      <div className="justify-start items-center gap-3 inline-flex w-full flex-wrap">
                        {jobDetails?.Skills?.map((data, key) => (
                          <>
                            {data.value ? (
                              <PreviewButton text={data.value} key={key} />
                            ) : (
                              <PreviewButton text={data} key={key} />
                            )}
                          </>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="self-stretch justify-start items-start inline-flex">
                    <div className="grow shrink basis-0 justify-start items-start gap-2 flex">
                      <div className="grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex w-full flex-wrap">
                        <p className="text-indigo-700 text-sm font-semibold font-['Poppins']">
                          Benefits
                        </p>
                        <ul className="self-stretch text-justify text-gray-600 text-sm font-medium list-disc pl-4">
                          {jobDetails?.Benefits !== undefined ?
                            <>
                              {Array.isArray(jobDetails?.Benefits) ? (
                                <>
                                  {jobDetails?.Benefits?.map(
                                    (data, key) => (
                                      <li key={key}>{ReactHtmlParser(data)}</li>
                                    )
                                  )}
                                </>
                              ) : (
                                <>
                                  {jobDetails?.Benefits.split(",")?.map(
                                    (item, index) => (
                                      <li key={index}>{ReactHtmlParser(item)}</li>
                                    )
                                  )}
                                </>
                              )}
                            </> : null}
                        </ul>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="w-full px-4 sm:px-12  py-8 bg-white bg-opacity-80 rounded-lg shadow border border-indigo-400 border-opacity-30 flex-col justify-start items-start gap-8 flex">
                  <div className="justify-start flex flex-col gap-y-6 w-full items-center inline-flex">
                    <div className="self-stretch flex-col justify-start items-start gap-4 inline-flex">
                      <p className="text-indigo-700 text-xl sm:text-2xl font-semibold font-['Poppins']">
                        Payment Details
                      </p>
                    </div>

                    <hr className="bg-[#777DEA26] w-full h-[1.35px]" />
                  </div>
                  <div className="self-stretch justify-start items-start inline-flex">
                    <div className="grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
                      <p className="text-indigo-700 text-sm font-semibold font-['Poppins']">
                        Payment plan
                      </p>
                      <div className="px-4 py-2 bg-white bg-opacity-50 rounded-lg border border-indigo-400 border-opacity-30 justify-center items-center gap-2 inline-flex">
                        <div className="w-4 h-4 bg-red-500 rounded-full border border-indigo-400 border-opacity-30" />
                        <p className="text-black text-base font-normal">
                          {jobDetails?.paymentPlan}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                    <p className="text-indigo-700 text-sm font-semibold font-['Poppins']">
                      Compensation
                    </p>
                    <p className="text-justify text-gray-600 text-sm font-medium font-['Poppins']">
                      This is the expected salary range for this job.
                    </p>
                    <p className="text-black text-base font-medium font-['Poppins']">
                      {jobDetails?.compensation}
                    </p>
                  </div>
                </div>

                {skillTest == undefined || skillTest == null ? null : 
                  <div className="w-full px-4 sm:px-6  py-6 bg-[#F9F4FD]  border border-[#E6E9F5] flex-col justify-start items-start gap-8 flex">
                    <div className="flex items-center w-full justify-between">
                      <p className="text-[#252430] text-[24px] leading-[1.2]">Skill Test</p>
                      <button className="p-2 rounded-lg bg-[#5843BD]">
                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6.91957 16.0909V0H10.9877V16.0909H6.91957ZM0.908203 10.0795V6.01136H16.9991V10.0795H0.908203Z" fill="#E6E9F5" />
                        </svg>
                      </button>
                    </div>

                    <div className="flex flex-col w-full gap-y-6">
                        {
                          skillTest?.map((test, i) => {
                            return (
                              <SkillTestPrevItem question={test.question} options={test.options} answer={test.answer} i={i + 1} />
                            )
                          })
                        }
                    </div>
                  </div>
                }

                <div className="self-stretch text-justify">
                  <span className="text-gray-600 text-sm font-medium font-['Poppins']">
                    Click on{" "}
                    <span className="text-indigo-700 text-sm font-bold font-['Poppins']">
                      ‘Publish Job’
                    </span>{" "}
                    if you are satisfied with everything.
                    <br />
                    Otherwise, click on{" "}
                  </span>
                  <span className="text-orange-500 text-sm font-bold font-['Poppins']">
                    ‘Edit Job’
                  </span>
                  <span className="text-gray-600 text-sm font-medium font-['Poppins']">
                    {" "}
                    to make changes.
                  </span>
                </div>
                <div className="self-stretch justify-between items-center inline-flex">
                  {hide == true ? null :
                    <button
                      className="px-5 py-3 rounded-lg shadow border border-orange-500 justify-start items-start gap-2.5 flex"
                      onClick={handleEdit}
                    >
                      <span className="text-center text-orange-500 text-base font-semibold font-['Poppins']">
                        Edit Job
                      </span>
                    </button>
                  }
                  {posting_stat.errMess !== null ? (
                    <h6 className="text-center text-orange-500 text-base font-semibold font-['Poppins']">
                      {posting_stat.errMess}
                    </h6>
                  ) : null}
                  <button
                    className="px-5 py-3 bg-indigo-700 rounded-lg shadow justify-start items-start gap-2.5 flex"
                    onClick={handleFadePayment}
                  >
                    <span className="text-center text-white text-base font-semibold font-['Poppins']">
                      {hide == true ? <>Go Back</> : <>Publish Job</>}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          {verificationLoad ? <LoaderModal text="Please wait while we post your job" /> : null}
        </div>
      }
      <ToastContainer />
      {/* {makePayment} */}
      {modal}
    </>
  );
}
